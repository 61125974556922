/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */

import './Reader/Boot'
import swal from 'sweetalert'

import { Random, Sha1 } from '@colibrio/colibrio-reader-framework/colibrio-core-encryption';
import '@colibrio/colibrio-reader-framework/colibrio-polyfill-dom';
import '@colibrio/colibrio-reader-framework/colibrio-polyfill-es6';
import '@colibrio/colibrio-reader-framework/colibrio-polyfill-geometry';
import '@colibrio/colibrio-reader-framework/colibrio-polyfill-webanimation';
import { IntRange } from './utils/IntRange';
import { IntSequence } from './utils/IntSequence';
import { VanillaReader } from './VanillaReader/VanillaReader';
import {
    IVanillaReaderAppEvent_APP_PWA_INSTALLED,
    IVanillaReaderAppEvent_APP_UI_OPTIONS_CHANGED,
    IVanillaReaderAppEvent_APP_UI_THEME_CHANGED, IVanillaReaderAppEvent_PUBLICATION_CLOSE_INTENT,
    IVanillaReaderAppEvent_PUBLICATION_RESOURCES_DELETE_INTENT,
    IVanillaReaderAppEvent_PUBLICATION_RESOURCES_DELETED,
    IVanillaReaderAppEvent_PUBLICATION_SHELF_UPDATED,
    VanillaReaderAppEvents,
    VanillaReaderEventBus,
} from './VanillaReader/VanillaReaderEventBus';
import {
    IVanillaReaderShelfItemData,
    IVanillaReaderUiColorPalette,
    IVanillaReaderUserProfileData,
} from './VanillaReader/VanillaReaderModel';
import { IVanillaReaderBookmarkDataStore } from './VanillaReaderDataStore/IVanillaReaderBookmarkDataStore';
import { IVanillaReaderHighlightDataStore } from './VanillaReaderDataStore/IVanillaReaderHighlightDataStore';
import { IVanillaReaderOptionsDataStore } from './VanillaReaderDataStore/IVanillaReaderOptionsDataStore';
import { IVanillaReaderPublicationDataStore } from './VanillaReaderDataStore/IVanillaReaderPublicationDataStore';
import {
    VanillaReaderBookmarkIndexedDbDataStore,
} from './VanillaReaderDataStore/VanillaReaderBookmarkIndexedDbDataStore';
import {
    VanillaReaderHighlightIndexedDbDataStore,
} from './VanillaReaderDataStore/VanillaReaderHighlightIndexedDbDataStore';
import { VanillaReaderOptionsIndexedDbDataStore } from './VanillaReaderDataStore/VanillaReaderOptionsIndexedDbDataStore';
import {
    VanillaReaderPublicationIndexedDbDataStore,
} from './VanillaReaderDataStore/VanillaReaderPublicationIndexedDbDataStore';

import {
    IVanillaReaderStreamedResourceStorage,
    VanillaReaderStreamedResourceIndexDbStorage,
} from './VanillaReaderDataStore/VanillaReaderStreamedResourceIndexDbStorage';
import { VanillaReaderUI } from './VanillaReaderUI/VanillaReaderUI';
import { IVanillaReaderUiOptionsData } from './VanillaReaderUI/VanillaReaderUiModel';
import getRandomHexString = Random.getRandomHexString;
import { ILegacyVanillaReaderPublicationStateData } from "./VanillaReader/VanillaReaderModelLegacy";
import { IosNativePinchZoomPreventer } from "./utils/platform-workarounds/IosNativePinchZoomPreventer";
import { BrowserDetector } from "@colibrio/colibrio-reader-framework/colibrio-core-base";

/*
 *
 * # INDEX.TS
 *
 * Hello fellow coder! How nice to see that you found your way here 🙂
 *
 * This is the entry point for the Imbiblio Reader application. This is where it all kicks off.
 *
 * At page load the app a VanillaReaderUI instance is created along with the Colibrio powered `VanillaReader` application.
 * Various settings and options from previous sessions are retrieved from local storage.
 *
 * After this is all done, the `VanillaReaderUI` will have rendered all of its components, and opened the
 * `VanillaReaderUiDialogOpenFile` dialog to let a user open a publication from their device, or from a list of
 * online sample publications.
 *
 *
 * ## SIMPLE Imbiblio Reader RELATIONAL DIAGRAM
 *
 *                                                                              |-------|
 *                                          |-------------------------|         |       |
 *                                          |                         |  ---→   |   V   |
 *                         | -- create --→  |     VanillaReaderUI     |  event  |   R   |
 *                         |                |                         |  ←---   |   E   |
 *        |----------|     | ←- callback--  |-------------------------|         |   v   |
 *        | index.ts |-----|                                       ↑            |   e   |
 *        |----------|     | -- create --→  |------------------|   |            |   n   |
 *           |     ↑       |                |                  | - |  -------→  |   t   |
 *           |    read     | ←- callback--  |  VanillaReader   |   |    event   |   B   |
 *           |     |                        |                  | ← |  --------  |   u   |
 *         store   |                        |------------------|   |            |   s   |
 *           |     |                               |      ↑        |            |       |
 *           ↓     |                               ↓      |        |            |-------|
 *    |----------------------------------------------------------------|
 *    |                  VanillaReaderDataStores                       |
 *    |----------------------------------------------------------------|
 *
 * The diagram above gives an overview of the relationships that the main modules have.
 *
 *
 * ## SERVICE WORKER
 *
 * I suggest that you learn the Imbiblio Reader basics before you dive into the great PWA void. PWAs has many quite complex
 * aspects that are not connected to anything Colibrio related. So one step at a time is my advice. But you do you 😉
 *
 * The Service Worker code is at the bottom of this file.
 *
 */

// Just a simple version name, so it's easy to keep track of current version at app start
const APP_VERSION_NAME = '220922-1520';

// Fall back background color for the app if no preferred palette has been specified in any saved settings.
const APP_THEME_BG_COLOR_DEFAULT = '#fff';

/**
 * QUERY STRING PARAMETERS
 * */


// Querystring parameter that acts as a flag to signal when the user has chosen to open a file using "Open with"
// on a PC.
const OPEN_FILE_FROM_QUEUE = document.location.hash.indexOf('open_file') >= 0;

// Querystring parameter that acts as a flag to signal if the user wants to install the Imbiblio Reader as a PWA.
const USE_SERVICEWORKER = document.location.search.indexOf('?pwa') >= 0;

// If its in PWA mode, we store application data on the device for offline support.
const USE_DEVICE_STORAGE = USE_SERVICEWORKER;

// Just a flag that is used later in this file to ignore the `slowNetwork` option.
const FORCE_HIGH_SPEED_CONNECTION = document.location.search.indexOf('force_high_speed_connection') >= 0;
const USER_PREFERS_REDUCED_MOTION = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

// Querystring parameter that is set if the application needs to perform migration of its data to a newer version of
// the database schema.
const PERFORM_MIGRATION = document.location.hash.indexOf('perform_migration') >= 0;

/**
 * APPLICATION MODULES
 *
 **/

// The Application View. Only cares about UI related stuff. No Colibrio Framework APIs are used in this module.
let vanillaReaderUi: VanillaReaderUI;

// The Application Logic. This is where you will find all references to the Colibrio Framework APIs.
let vanillaReader: VanillaReader;

/**
 * DATA STORAGES MODULES
 *
 * You can implement any of these interfaces to store data in your own custom storage, including on your server.
 *
 * */

// Storage client used for storing options and settings data.
let vanillaReaderOptionsDataStore: IVanillaReaderOptionsDataStore;

// Storage client used for storing bookmark data.
let vanillaReaderBookmarkStorage: IVanillaReaderBookmarkDataStore;

// Storage client used for storing highlight data.
let vanillaReaderHighlightStorage: IVanillaReaderHighlightDataStore;

// Storage client used for storing publication data.
let vanillaReaderPublicationDataStore: IVanillaReaderPublicationDataStore;

// This storage client uses IndexedDB to store binary resource data for long term offline storage.
// See the "Service Worker and Offline Storage" section in the README.
let vanillaReaderStreamedResourceStorage: IVanillaReaderStreamedResourceStorage;

/*
*
* FILE HANDLING API
*
* This API lets a PWA register as handler for specific file formats. This means that the PWA  will show up as an option
* in the "Open with" dialog on a PC, and that it can be set as the default application for formats.
* since your PWA can now act more as a "proper PC application"
*
* This is a fairly new API introduced in June 2022. So far, it is supported in Chromium based browsers such as
* Chrome and Edge on all PC operating systems and on ChromeOS.
*
* Read more at :
* https://web.dev/file-handling/
*
* */

if ('launchQueue' in window) {
    // @ts-ignore
    window.launchQueue.setConsumer(launchParams => {
        handleIncomingLaunchFiles(launchParams.files).catch(console.warn);
    });
}

// This function is run anytime there is a file that has been opened from the desktop operating system.
async function handleIncomingLaunchFiles(files: any) {
    if (files.length > 0) {
        const file = await files[0].getFile() as File;
        await createVanillaReaderModules();
        vanillaReader.loadPublicationFromFile(file, undefined, USE_DEVICE_STORAGE).catch(console.warn);
    }
}

/*
*
*  MAIN APPLICATION SETUP
*
* @see ./boot.ts
*
* */

window.addEventListener('load', async () => {
    return createVanillaReaderModules().catch(console.warn);
});


async function createVanillaReaderModules() {
    /*
    *
    * Application storage client setup
    *
    * */

    // Storage client for storage of application options for UI and reading.
    vanillaReaderOptionsDataStore = new VanillaReaderOptionsIndexedDbDataStore('options.vanillareader.colibrio.com');

    // Storage client for storage of publication metadata.
    vanillaReaderPublicationDataStore = new VanillaReaderPublicationIndexedDbDataStore('publication.vanillareader.colibrio.com');

    // @ts-ignore
    window.reader.vanillaReaderPublicationDataStore = vanillaReaderPublicationDataStore

    // Storage client for storage of bookmark data.
    vanillaReaderBookmarkStorage = new VanillaReaderBookmarkIndexedDbDataStore('bookmarks.vanillareader.colibrio.com');

    // Storage client for storage of highlight data.
    vanillaReaderHighlightStorage = new VanillaReaderHighlightIndexedDbDataStore('highlights.vanillareader.colibrio.com');

    // Storage client for storage of downloaded publication resource data. This is used for offline storage.
    vanillaReaderStreamedResourceStorage = new VanillaReaderStreamedResourceIndexDbStorage('data.vanillareader.colibrio.com');

    /*
    *
    * Data migration
    *
    * */

    // If the application has been upgraded to a newer version
    if (PERFORM_MIGRATION) {
        let numberOfItemsMigrated = await performDataMigrationFromLocalStorage().catch(console.warn);

        // @ts-ignore
        if (numberOfItemsMigrated > 0) {
            alert('Migration of data has been performed after version update. The application will reload once more.');
            window.location.href = `index.html${USE_DEVICE_STORAGE ? '?pwa=true' : ''}`;
        } else {
            // Remove the perform_migration hash
            window.location.hash = '';
        }
    }

    /*
    *
    * Main module setup
    *
    * */

    // We start the application by "booting up" the UI. When we have a rendered UI we can pass the reader view element
    // to the Imbiblio Reader reading system.
    vanillaReaderUi = await setupVanillaReaderUI();

    // Toast app version for easier service worker debugging etc.
    vanillaReaderUi.toaster.toast(`App version ${APP_VERSION_NAME}`).catch(console.error);

    // Now that we have the app ui up and running we have a place to mount the reading system.
    vanillaReader = await setupVanillaReader(licenseApiKey);

    // Prevent unintended pinch zoom in the UI for iOS. This does not affect the Colibrio Framework zoom features.
    if (BrowserDetector.isOS('iOS')) {
        let iosPinchZoomPreventer = new IosNativePinchZoomPreventer();
        iosPinchZoomPreventer.observe();
    }

    // The navigator.isOnline attribute is sadly not a reliable indicator of Internet access.
    // In order to determine if the device can reach our server we need to "ping" periodically it and wait for a reply.
    checkNetworkState();

    /**
     *
     * BROWSER CONSOLE HELPERS
     *
     * Let's export the VanillaReader and the VanillaReaderUI so that we can play around with the APIs
     * more easily. The VanillaReader is meant to be a fun playground! 😊
     *
     * */

    // @ts-ignore
    window.vanillaReader = vanillaReader;
    // @ts-ignore
    window.vanillaReaderUi = vanillaReaderUi;
    // @ts-ignore
    window.VanillaReaderEventBus = VanillaReaderEventBus;
    // @ts-ignore
    window.VanillaReaderAppEvents = VanillaReaderAppEvents;

    // Two very handy utility classes for working with range data. Also classes good to have access to in the console.
    // @ts-ignore
    window['IntRange'] = IntRange;
    // @ts-ignore
    window['IntSequence'] = IntSequence;

}

/*
*
* Sets up the `VanillaReaderUI` instance. This is class encapsulates all things that is user interface related.
* This class, and all its members, has no Colibrio Reader Framework code, it is completely "swappable" to any
* frontend technology.
*
 */
async function setupVanillaReaderUI(): Promise<VanillaReaderUI> {

    let shelfItems = await fetchStoredShelfItems();
    let vanillaReaderUi = new VanillaReaderUI(document.getElementById('root')!, vanillaReaderPublicationDataStore, vanillaReaderOptionsDataStore, USE_DEVICE_STORAGE, shelfItems);


    // The user has decided to remove all stored resources from the device storage.
    VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_PUBLICATION_RESOURCES_DELETE_INTENT>(VanillaReaderAppEvents.PUBLICATION_RESOURCES_DELETE_INTENT, async (ev: CustomEvent<IVanillaReaderAppEvent_PUBLICATION_RESOURCES_DELETE_INTENT>) => {
        let shelfItemData = ev.detail.shelfItem;

        if (shelfItemData.id) {
            await vanillaReaderPublicationDataStore.deletePublicationDataByFileName(shelfItemData.fileName);
            await vanillaReaderPublicationDataStore.deletePublicationOptionsData(shelfItemData.id);
            await vanillaReaderPublicationDataStore.deletePublicationCacheData(shelfItemData.id);
            await vanillaReaderPublicationDataStore.clearPublicationReadingPositionData(shelfItemData.id);
            await vanillaReaderStreamedResourceStorage.deleteResourceByName(shelfItemData.fileName);
            let shelfItems = await fetchStoredShelfItems();

            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_PUBLICATION_SHELF_UPDATED>(new CustomEvent<IVanillaReaderAppEvent_PUBLICATION_SHELF_UPDATED>(VanillaReaderAppEvents.PUBLICATION_SHELF_UPDATED, {
                detail: {
                    shelfItems: shelfItems,
                },
            }));

            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_PUBLICATION_RESOURCES_DELETED>(new CustomEvent<IVanillaReaderAppEvent_PUBLICATION_RESOURCES_DELETED>(VanillaReaderAppEvents.PUBLICATION_RESOURCES_DELETED));


        } else {
            console.warn(`Unable to delete publication ${shelfItemData.fileName} since it did not have an have a valid id.`);
        }

    });

    // When the user wants to open a new book we reload the index.html page to start from a clean state.
    // We take care to keep the `pwa=true` query parameter on reload.
    VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_PUBLICATION_CLOSE_INTENT>(VanillaReaderAppEvents.PUBLICATION_CLOSE_INTENT, (_ev: CustomEvent<IVanillaReaderAppEvent_PUBLICATION_CLOSE_INTENT>) => {
        saveVanillaReaderOptionsState().then(() => {
            window.location.href = `index.html${USE_DEVICE_STORAGE ? '?pwa=true' : ''}`;
        }).catch(console.error);
    });


    // The user has changed color theme, so we need to make sure that the background etc. are changed accordingly.
    VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_APP_UI_THEME_CHANGED>(VanillaReaderAppEvents.APP_UI_THEME_CHANGED, (ev: CustomEvent<IVanillaReaderAppEvent_APP_UI_THEME_CHANGED>) => {
        if (ev.detail.colorPalette) {
            changeAppColorTheme(ev.detail.colorPalette);
        }
    });

    // Event fired when the VanillaReaderUI has updated settings. These are saved to the store in the `saveVanillaReaderOptionsState`
    // function.
    VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_APP_UI_OPTIONS_CHANGED>(VanillaReaderAppEvents.APP_UI_OPTIONS_CHANGED, (ev: CustomEvent<IVanillaReaderAppEvent_APP_UI_OPTIONS_CHANGED>) => {
        saveVanillaReaderUiOptions(ev.detail.uiOptions).catch(console.warn);
    });

    return vanillaReaderUi;

}

/**
 *
 * Sets up the `VanillaReader` instance. This is the "application logic module", the code that uses the Colibrio Reader
 * Framework.
 *
 */
async function setupVanillaReader(licenseApiKey: string): Promise<VanillaReader> {

    // The user profile data holds the unique user identifier that is used as token when loading publications.
    const userProfileData: IVanillaReaderUserProfileData = await fetchOrCreateUserProfile();

    // Get any previously saved framework options from local storage. This will later be used to initialize the
    // VanillaReader to its previous state.
    const storedVanillaReaderOptionsData = await vanillaReaderOptionsDataStore.fetchVanillaReaderOptionsData();

    const vanillaReaderViewOptions = storedVanillaReaderOptionsData?.viewOptions;

    if (vanillaReaderViewOptions) {
        if (USER_PREFERS_REDUCED_MOTION) {
            vanillaReaderViewOptions.useReducedMotion = true;
        }
    }

    // Needed to add a ts-ignore as the TS definitions do not recognize the `downlink` property :/
    // @ts-ignore
    const slowNetwork: boolean = navigator.connection?.downlink < 1.5 && !FORCE_HIGH_SPEED_CONNECTION;

    const vanillaReader = new VanillaReader(vanillaReaderUi.vanillaReaderViewElement, licenseApiKey, userProfileData, {
        slowNetwork,
        storeOnDevice: USE_DEVICE_STORAGE,
        viewOptions: vanillaReaderViewOptions,
        syncMediaOptions: storedVanillaReaderOptionsData?.syncMediaOptions,

        /**
         *
         * Set book loading icon, whenever a book content is loading...
         *
         * */
        customReaderViewContentOnLoading: '<div id="vanilla-reader__reader-view__loader">📖</div>',
        // customReaderViewContentOnLoading: '<div id="vanilla-reader__reader-view__loader">🍦</div>',
        customReaderViewContentOnLoadError: '<div id="vanilla-reader__reader-view__load-error">💥</div>'

    }, vanillaReaderStreamedResourceStorage, vanillaReaderBookmarkStorage, vanillaReaderHighlightStorage, vanillaReaderPublicationDataStore, vanillaReaderOptionsDataStore);

    // If the `publication_url` query parameter was set we should load a publication from a web server right away.
    (async () => {
        try {

            // Hide Loader.
            // @ts-ignore
            window.reader.loader('hide')

            /*
            * Decode the Token from url params and load the book
            *
            * from decoded bookURL via imbiblioreader dotnet site.
            *
            * */
            // @ts-ignore
            window.reader.vanillaReaderUi = vanillaReaderUi

            // @ts-ignore
            window.reader.vanillaReader = vanillaReader

            // @ts-ignore
            window.reader.loadPublicationFromInitialUrl(USE_DEVICE_STORAGE).catch(console.error);

        } catch (error) {

            let msg = 'Error retrieving initial URL'
            console.error(msg + ': ', error)
            swal('Failed!', msg, 'error')
            throw new Error('Stop the app!')

        }
    })();

    // Add event listeners for connection related events. This check is unfortunately not enough as it only reports if
    // there is an active connection, not that a connection can reach the Internet. In addition to these events we also
    // set up a reoccurring fetch request in the `checkNetworkState()` function.

    window.addEventListener('online', () => {
        vanillaReader.isOffline = false;
    });

    window.addEventListener('offline', () => {
        vanillaReader.isOffline = true;
    });

    return vanillaReader;
}

// As soon as the page is hidden (tab has been closed, activity has ended or tab has been switched) we save the app state.
// Better safe than sorry!
document.addEventListener('visibilitychange', () => {
    if (document.visibilityState !== 'visible') {
        console.log('Document is no longer visible.');

        //await saveVanillaReaderOptionsState();

        // @ts-ignore
        // window.reader.updatePublicationData('visibilitychange')

    }
});

// This function retrieves the publications that have been stored in the `vanillaReaderStreamedResourceStorage`.
async function fetchStoredShelfItems(): Promise<IVanillaReaderShelfItemData[]> {
    return new Promise(async (resolve) => {
        let shelfItems: IVanillaReaderShelfItemData[] = [];
        // Get all stored publications.
        let vanillaReaderPublicationData = await vanillaReaderPublicationDataStore.fetchAllPublicationData();
        console.log(`VanillaReaderPublicationdata: ${vanillaReaderPublicationData}`);
        for (const item of vanillaReaderPublicationData) {
            if (item.id && item.fileSourceUri) {
                let hasOfflineData = await vanillaReaderStreamedResourceStorage.hasResource(item.fileSourceUri);
                if (hasOfflineData && item.fileSourceUri && item.fileSourceUri.includes('://')) {
                    let readingPositionData = await vanillaReaderPublicationDataStore.fetchPublicationReadingPositionData(item.id);
                    let shelfItem: IVanillaReaderShelfItemData = item;
                    shelfItem.latestReadingPositionData = readingPositionData;
                    shelfItem.hasOfflineData = hasOfflineData;
                    shelfItems.push(shelfItem);
                }
            }
        }
        setTimeout(() => {
            resolve(shelfItems);
        }, 1000);
    });
}

fetchStoredShelfItems().then((shelfItems) => {
    console.log(`shelfItems: ${shelfItems}`);
}).catch((error) => {
    console.error('Error fetching shelf items:', error);
});

// This function returns a IVanillaReaderUserProfileData from LocalStorage. If no user data has been stored previously,
// the data is created object and stored.
async function fetchOrCreateUserProfile(): Promise<IVanillaReaderUserProfileData> {
    let userProfileData: IVanillaReaderUserProfileData;
    let userProfileDataString = window.localStorage.getItem('VanillaReaderUserProfileData');

    if (!userProfileDataString) {
        let uuid = getRandomHexString(12);
        let token = createDigestFromString(uuid);
        userProfileData = {
            id: uuid,
            token,
        };
        window.localStorage.setItem('VanillaReaderUserProfileData', JSON.stringify(userProfileData));
    } else {
        userProfileData = JSON.parse(userProfileDataString);
    }

    return userProfileData;
}

// This function creates a SHA256 hash from a string value. This is to demonstrate and emphasise that the user id
// should always be obfuscated before used with the framework.
function createDigestFromString(stringValue: string) {
    let sha = new Sha1();
    sha.append(stringValue);
    return sha.digestToHex();
}

/*
*
* This function saves the current application state by calling `serializeAppState` on the `VanillaReader` instance, and
* then saving it to the device using the active `IVanillaDataStore` instance (currently `VanillaReaderLocalStorageDataStore`).
*
 */
async function saveVanillaReaderOptionsState() {
    let appState = await vanillaReader.serializeToOptionsData();
    if (appState) {
        if (appState.viewOptions) {
            await vanillaReaderOptionsDataStore.setVanillaReaderOptionsData(appState);
        }
    }
    let uiOptions = vanillaReaderUi.serializeToUiOptions();
    if (uiOptions) {
        saveVanillaReaderUiOptions(uiOptions).catch(console.warn);
    }
}

// Save the UI settings to the data store.
async function saveVanillaReaderUiOptions(settings: IVanillaReaderUiOptionsData) {
    await vanillaReaderOptionsDataStore.setVanillaReaderUiOptionsData(settings);
}

/*
*
* This simple function just adapts the background color to fit with the VanillaReaderUI and VanillaReader settings.
* The `palette` argument is sent from the VanillaReaderUi. The palettes are defined in the `VanillaReaderAppUiDefaults`
* class.
*
*/
const changeAppColorTheme = (palette: IVanillaReaderUiColorPalette) => {
    if (palette) {
        document.documentElement.style.backgroundColor = palette.backgroundDark;
    } else {
        document.documentElement.style.backgroundColor = APP_THEME_BG_COLOR_DEFAULT;
    }
};

/*
*
* This function simply pings an HTML page on our server to see if we are still connected to the network.
* This is still the only real way to check connectivity since the existing browser APIs are very hard to work with
* reliably.
*
* When we get a timeout we set the `VanillaReader.isOffline` property to communicate to the application that it should
* change its internal behaviour to adapt.
*
 */
function checkNetworkState() {
    try {
        fetch('https://demo.colibrio.com/ping.html', {
            mode: 'no-cors',
            method: 'HEAD',
        }).then((res: Response) => {
            // Any status less than 500 means that we got something back from the server.
            if (res.status < 500) {
                vanillaReader.isOffline = false;
            }
        }).catch((_err) => {
            vanillaReader.isOffline = true;
        });
    } catch {
        vanillaReader.isOffline = true;
    }
    window.setTimeout(checkNetworkState, 2500);
}

/**
 *
 * PWA SERVICE WORKER SETUP
 *
 * This section is all about registering and managing updates of the `service-worker.ts` script.
 *
 * There are entire books just dedicated to Service Workers. They are an awesome technology, byt there are many caveats
 * to be aware of, so if you wish to make your reader app progressive, read up on service workers before you start your
 * journey.
 *
 * Have a look at this article at web.dev https://web.dev/offline-cookbook/ and this one specifically about caching
 * https://web.dev/service-worker-caching-and-http-caching/
 *
 **/


let serviceWorkerUpdated: boolean = false;

// Note the `USE_SERVICEWORKER` constant that acts as a switch to turn on the service worker based on an url parameter.
if ('serviceWorker' in navigator) {
    let updatedServiceWorker: ServiceWorker | null;

    navigator.serviceWorker.register(
        new URL('service-worker.ts', import.meta.url),
        {
            type: 'module',
            scope: '/',
            updateViaCache: 'none',
        },
    ).then((registration: ServiceWorkerRegistration) => {

        registration.addEventListener('updatefound', () => {

            console.log('Update to the installed Service Worker found.');

            // Check if the new service worker is in the process of being installed.
            updatedServiceWorker = registration.installing;

            if (updatedServiceWorker) {

                // Add an event listener so that we get now when the installation is done.
                updatedServiceWorker.addEventListener('statechange', (_ev: Event) => {

                    if (updatedServiceWorker?.state === 'installed' && navigator.serviceWorker.controller) {
                        console.log('Updated Service Worker installed.');
                        serviceWorkerUpdated = true;

                        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_APP_PWA_INSTALLED>(new CustomEvent<IVanillaReaderAppEvent_APP_PWA_INSTALLED>(VanillaReaderAppEvents.APP_PWA_INSTALLED));

                        // Send a postMessage to the Service Worker to tell it to skip waiting and set the current page
                        // as controller. This will in turn trigger a `controllerchange` event that is handled elsewhere
                        // in this file.
                        updatedServiceWorker.postMessage({ eventName: VanillaReaderAppEvents.APP_PWA_SKIP_WAITING_INTENT });
                    }
                });
            } else {
                console.error('setupServiceWorker()', 'Unable to install Service Worker.');
            }

        });

    }).catch(console.error);

    // Add event listener so that we get an alert when the service worker has been set as the controller of the page.
    navigator.serviceWorker.addEventListener('controllerchange', () => {

        // If the service worker is new we reload the page in order to make sure that it is activated.
        if (serviceWorkerUpdated) {

            let latestDataMigrationDateStamp = window.localStorage.getItem('VanillaReaderAppDataMigrationDateStamp');
            console.log('Latest data migration date', latestDataMigrationDateStamp);

            //alert('The app has been updated to a new version and will be restarted.');

            if (latestDataMigrationDateStamp) {
                // Migration done, we'll just reload
                //window.location.href = `index.html${USE_DEVICE_STORAGE ? '?pwa' : ''}`;
            } else {
                // Reload to perform migration
                /* window.location.href = `index.html${USE_DEVICE_STORAGE ?
                    '?pwa&#perform_migration' :
                    '?#perform_migration'}`; */
            }

        }
    });
}

/*
*
* COLIBRIO READER FRAMEWORK LICENSE CHECK
*
* */

// This line is needed in order to let Parcel insert the license key during the build process.
declare const process: MockedNodeProcess;

// `process.env.LICENSE_API_KEY` is replaced by the license key during Parcels build process.
// Don't worry too much about this at the moment, we have more important things to learn :)
const licenseApiKey = process.env.LICENSE_API_KEY;

if (!licenseApiKey) {
    showAPIKeyMissing();
}

// Displays a message so that you don't forget to add an API key to the project.
function showAPIKeyMissing() {
    document.body.innerHTML = `
            <h1>
            License API Key missing
            </h1>
            <p style="font-size: 16px">
                Please edit the .env file and add your License API Key:
            </p>

            <code style="font-size: 16px">LICENSE_API_KEY=&lt;Your API Key&gt;</code>;

            <p>
                Then rebuild your project with <code>yarn start</code>
            </p>
        `;
}

/**
 *
 * DATA MIGRATION CODE
 *
 * */


// This method is used to migrate data from the older, less persistent storage that used the LocalStorage API.
export async function performDataMigrationFromLocalStorage(): Promise<number> {
    let numberOfItemsMigrated: number = 0;

    // As LocalStorage is a simple key value store with no "namespacing", we need to loop through all items in the store
    // and determine if we should migrate the item or not.
    for (const key in localStorage) {
        let data: ILegacyVanillaReaderPublicationStateData | undefined;
        let item = localStorage.getItem(key);

        if (item) {
            try {
                data = JSON.parse(item);
            } catch (e) {
                continue;
            }

            if (data === undefined) {
                continue;
            }

            if (isLegacyVanillaReaderPublicationStateData(data) && data.id) {

                // Check if the publication has already been migrated.
                if (await vanillaReaderPublicationDataStore.hasPublicationData(data.id)) {
                    console.log(`performDataMigrationFromLocalStorage: "${data.fileName}" has already been migrated.`);
                    continue;
                }

                console.log(`performDataMigrationFromLocalStorage: Starting migration for publication "${data.fileName}"`);

                await vanillaReaderPublicationDataStore.setPublicationData(data.id!, {
                    id: data.id!,
                    fileName: data.fileName,
                    coverImageAsBase64: data.coverImageAsBase64,
                    fileSourceUri: data.fileSourceUri,
                    format: data.fileSourceUri,
                    hasMoSyncMedia: data.hasMoSyncMedia,
                    isScripted: data.isScripted,
                    isFixedLayout: data.isFixedLayout,
                    isRtl: data.isRtl,
                    isAudiobook: data.isAudiobook,
                    metadata: data.metadata,
                    url: data.url,
                    title: data.title,
                }).catch(console.warn);

                console.log(`performDataMigrationFromLocalStorage: Migrated publication data for "${data.fileName}"`);

                if (data.highlights && data.highlights.length > 0) {
                    for (const highlight of data.highlights) {
                        highlight.publicationId = data.id;
                        await vanillaReaderHighlightStorage.addHighlight(highlight).catch(console.warn);
                    }
                    console.log(`performDataMigrationFromLocalStorage: Migrated ${data.highlights.length} highlights`);
                }

                if (data.bookmarks && data.bookmarks.length > 0) {
                    for (const bookmark of data.bookmarks) {
                        bookmark.publicationId = data.id;
                        await vanillaReaderBookmarkStorage.addBookmark(bookmark).catch(console.warn);
                    }
                    console.log(`performDataMigrationFromLocalStorage: Migrated ${data.bookmarks.length} bookmarks`);
                }


                if (data.latestReadingPosition) {
                    await vanillaReaderPublicationDataStore.setPublicationReadingPositionData(
                        data.id!,
                        {
                            publicationId: data.id,
                            timestamp: data.latestReadingDateTime || 0,
                            locator: data.latestReadingPosition.locator,
                            navItemData: data.latestReadingPosition.navItemData,
                            documentIndex: data.latestReadingPosition.documentIndex,
                        },
                    ).catch(console.warn);
                    console.log(`performDataMigrationFromLocalStorage: Migrated latest reading position data.`);
                }

                numberOfItemsMigrated++;

            }


            console.log(`performDataMigrationFromLocalStorage: Done migrating publication "${data.fileName}"`);
        }
    }

    window.localStorage.setItem(
        'VanillaReaderAppDataMigrationDateStamp',
        Date.now().toString()
    );
    return numberOfItemsMigrated;
}

function isLegacyVanillaReaderPublicationStateData(data: object): data is ILegacyVanillaReaderPublicationStateData {
    return (data.hasOwnProperty('id') && data.hasOwnProperty('fileName'));
}
