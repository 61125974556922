/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {createFocusTrap, FocusTrap} from 'focus-trap';
import {
    IVanillaReaderNavigationItem, VanillaBookmarkReadingPositionIntentEventCallback, VanillaDialogClosedCallback,
    VanillaReaderNavigationType,
    VanillaVoidCallback
} from '../VanillaReader/VanillaReaderModel';
import {VanillaReaderUI} from './VanillaReaderUI';
import {VanillaUiNavigationIntentEventCallback} from "./VanillaReaderUiModel";
import {BrowserDetector} from "@colibrio/colibrio-reader-framework/colibrio-core-base";

/**
 * # VanillaReaderUiMenu
 *
 * ## RESPONSIBILITIES
 *
 * This class hosts a number of child UI components that make up the bottom menu.
 *
 * - Progression Slider
 * - Media Player
 * - Action Buttons
 *
 * ## RELATED TYPES
 * - VanillaReaderUiMenuController
 *
 */

export class VanillaReaderUiMenu {
    private _menu: HTMLElement;
    private _buttonMenuHandle: HTMLElement;
    private _buttonPrevious: HTMLElement;
    private _buttonNext: HTMLElement;
    private _sliderProgression: HTMLInputElement;
    private _sliderProgressionLabel: HTMLElement;
    private _sliderProgressionChangeTimeout: number | undefined;
    private _buttonToc: HTMLElement;
    private _buttonBookmarks: HTMLElement;
    private _buttonHighlights: HTMLElement;
    private _buttonHelp: HTMLElement;
    private _buttonFile: HTMLElement;
    private _buttonSettings: HTMLElement;
    private _buttonBookmarkReadingPosition: HTMLElement;
    private _buttonReadAloudOpen: HTMLElement;
    private _buttonReadAloudClose: HTMLElement;
    private _buttonPanZoomActivate: HTMLElement;
    private _buttonPanZoomDeactivate: HTMLElement;
    private _buttonSearch: HTMLElement;
    private _timelineLength: number = 0;
    private _hostDocument: Document;
    private _enablePanZoomButton: boolean = !BrowserDetector.isPlatform('mobile');

    private _touchEventCoords: number[] | undefined;

    private _focusTrap: FocusTrap | undefined;

    private _menuHandleClickedCallback: VanillaVoidCallback | undefined;
    private _openHelpDialogCallback: VanillaVoidCallback | undefined;
    private _swipeDownCallback: VanillaVoidCallback | undefined;
    private _fileOpenCallback: VanillaVoidCallback | undefined;
    private _visibleCallback: VanillaVoidCallback | undefined;
    private _hiddenCallback: VanillaDialogClosedCallback | undefined;
    private _navigateNextCallback: VanillaUiNavigationIntentEventCallback | undefined;
    private _navigatePreviousCallback: VanillaUiNavigationIntentEventCallback | undefined;
    private _navigateToTimelinePositionCallback: VanillaUiNavigationIntentEventCallback | undefined;
    private _showReadAloudControlsCallback: VanillaVoidCallback | undefined;
    private _hideReadAloudControlsCallback: VanillaVoidCallback | undefined;
    private _panZoomActivateCallback: VanillaVoidCallback | undefined;
    private _panZoomDeactivateCallback: VanillaVoidCallback | undefined;
    private _searchPublicationCallback: VanillaVoidCallback | undefined;
    private _bookmarkReadingPositionIntentCallback: VanillaBookmarkReadingPositionIntentEventCallback | undefined;
    private _contentsDialogOpenCallback: VanillaVoidCallback | undefined = undefined;

    private _style = `
        <style>
            #vanilla-reader__menu {
                position: fixed;
                opacity: 0;
                display: flex;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                //padding: 1em;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-around;
                box-sizing: border-box;
                z-index: 2;
                background-color: #d2af50;
                box-shadow: 0 -3px 16px 0 rgb(0 0 0 / 30%);
               // border-radius: 16px 16px 0 0;
               border-radius:0px;
                border: none;
                border-top: 1px solid var(--vanilla-reader__color__ui__outline-dark);
                pointer-events: none;
                transition: bottom 0.2s ease-out;
                will-change: bottom;
            }

            @media (prefers-reduced-motion: reduce) {
                #vanilla-reader__menu {
                    transition: opacity 0.2s ease-out;
                }
            }

            #vanilla-reader__menu button {
                flex-direction: column;
                min-height: 48px;
                min-width: 64px;
                font-size: x-large;
                color: var(--vanilla-reader__color__ui__foreground-dark);
                border-color: transparent;
                background-color: transparent;
            }

            #vanilla-reader__menu button .vanilla__button-icon {
                display: block;
                width: 32px;
                height: 32px;
                margin-bottom: 5px;
            }

            #vanilla-reader__menu button .vanilla__button-label {
                font-family: 'Lato';
                font-size: 10px;
                font-weight: 600;
                min-height: 20px;
                display: block;
            }

            #vanilla-reader__menu__button-handle {
                height: 24px;
                min-height: 24px!important;
                width: 100%;
                margin: 8px;
                align-self: center;
                background-color: transparent;
                border: 0;
            }

            #vanilla-reader__menu__button-handle span {
                width: 100%;
                display: block;
                font-size: x-large;
                color: var(--vanilla-reader__color__ui__foreground-dark);
            }

            #vanilla-reader__menu__mediaplayer-container {
                width: 100%;
                justify-content: center;
                font-size: x-large;
            }

            #vanilla-reader__menu__slider-container {
                display: flex;
                flex-grow: 1;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
                max-width: 900px;
            }

            #vanilla-reader__menu__slider-progression {
                flex-grow: 8;
                margin: 0.5em;
                padding: 0;
            }

            #vanilla-reader__menu__slider-progression__label {
                font-weight: 600;
            }

            #vanilla-reader__menu__slider-container button {
                font-size: x-large;
                align-content: center;
                min-width: 48px;
                margin: 8px;
            }

            #vanilla-reader__menu__action-button-container {
                display: flex;
                flex-grow: 1;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                max-width: 900px;
                overflow-x: auto;
            }

            #vanilla-reader__menu__slider-progression__label {
                width: 100%;
                min-height: 15px;
                justify-self: stretch;
                text-align: center;
                margin: 0.5em;
                font-size: 0.8em;
                color: var(--vanilla-reader__color__ui__foreground-dark);
            }

            #vanilla-reader__dialog-highlight__form__comment {
                width: 100%;
                height: 10em;
                margin-bottom: 1em;
            }

            #vanilla-reader__menu__button-bookmark-add {}

            #vanilla-reader__menu__button-bookmark-remove {
                display: none;
            }

            #vanilla-reader__menu__button-readaloud-hide {
                display: none;
            }

            #vanilla-reader__menu__action-button-container img{
                width:80px;
            }

            #vanilla-reader__menu__button-next, #vanilla-reader__menu__button-previous{
                color:#fff!important;
            }

        </style>`;
        /* <button id="vanilla-reader__menu__button-help" title="Open help dialog"><span class="vanilla__button-icon" aria-hidden="true">help</span><span aria-hidden="true" class="vanilla__button-label">Help</span></button>
        <button id="vanilla-reader__menu__button-file" title="Open new book" accesskey="q"><span class="vanilla__button-icon" aria-hidden="true">folder_open</span><span aria-hidden="true" class="vanilla__button-label">Open file</span></button>  */
    private _template = `
        <nav id="vanilla-reader__menu" title="Menu section" tabindex="0" aria-hidden="true">
            <button id="vanilla-reader__menu__button-handle" title="Close menu" accesskey="x" tabindex="0"><span aria-hidden="true">drag_handle</span></button>
            <div id="vanilla-reader__menu__mediaplayer-container"></div>
            <div id="vanilla-reader__menu__slider-container" tabindex="0">
                <label id="vanilla-reader__menu__slider-progression__label" aria-hidden="true" for="vanilla-reader__menu__slider-progression__label"></label>
                <button id="vanilla-reader__menu__button-previous" title="Go to previous"><span aria-hidden="true">navigate_before</span></button>
                <input type="range" id="vanilla-reader__menu__slider-progression" title="Reading progression slider">
                <button id="vanilla-reader__menu__button-next" title="Go to next"><span aria-hidden="true">navigate_next</span></button>
            </div>
            <hr>
            <div id="vanilla-reader__menu__action-button-container">
                <button id="vanilla-reader__menu__button-help" title="Open help dialog" style="color: #fff;"><span class="vanilla__button-icon" aria-hidden="true" style="color: #fff;">help</span><span aria-hidden="true" class="vanilla__button-label">Help</span></button>
                <button id="vanilla-reader__menu__button-toc" title="Open table of content dialog" accesskey="c"><img src="./icons/contents.png" /></button>
                <button id="vanilla-reader__menu__button-readaloud-show" title="Show read aloud controls" accesskey="r"><img src="./icons/hearit.png"/></button>
                <button id="vanilla-reader__menu__button-readaloud-hide" title="Show read aloud controls"><img src="./icons/hearit.png"/></button>
                <button id="vanilla-reader__menu__button-search" title="Search book" accesskey="s"><img src="./icons/search.png" /></button>
                <button id="vanilla-reader__menu__button-imbiblio" title="Refresh Imbiblioapp" accesskey="im" type="button" onclick="reader.appRefresh(this)">
                <img src="./icons/logo.png"/>
                </button>
                <button id="vanilla-reader__menu__button-highlights" title="Open highlights dialog" accesskey="h"><img src="./icons/highlight.png"/></button>
                <button id="vanilla-reader__menu__button-bookmarks" title="Open bookmarks dialog" accesskey="o"><img src="./icons/bookmark.png" /></button>
                <button id="vanilla-reader__menu__button-bookmark-readingposition" title="Bookmark current position" accesskey="b" style="color: #fff; font-size: 35px;"><span class="vanilla__button-icon" aria-hidden="true">bookmark_add</span><span aria-hidden="true" class="vanilla__button-label" style="font-weight: bold;">Add Bookmark</span></button>
                <button id="vanilla-reader__menu__button-panzoom-activate" title="Activate Pan and Zoom mode"><img src="./icons/zoom.jpg" /></button>
                <button id="vanilla-reader__menu__button-panzoom-deactivate" title="Exit Pan and Zoom mode" style="color: #fff; font-size: 35px;"><span class="vanilla__button-icon" aria-hidden="true">zoom_out_map</span><span aria-hidden="true" class="vanilla__button-label">Exit Zoom</span></button>
                <button id="vanilla-reader__menu__button-settings" title="Open settings dialog" accesskey="t"> <img src="./icons/settings.png" /></button>
            </div>
        </nav>`;

    constructor(
        private _hostElement: HTMLElement,
        insertPosition: InsertPosition = 'beforeend',
        private _hostContext: VanillaReaderUI,
    ) {

        this._hostElement.insertAdjacentHTML(insertPosition, this._template);
        this._hostDocument = _hostElement.ownerDocument!;

        this._menu = this._hostDocument.getElementById('vanilla-reader__menu')!;
        this._menu.insertAdjacentHTML('afterbegin', this._style);
        this._buttonMenuHandle = this._hostDocument.getElementById('vanilla-reader__menu__button-handle')!;
        this._buttonPrevious = this._hostDocument.getElementById('vanilla-reader__menu__button-previous')!;
        this._buttonNext = this._hostDocument.getElementById('vanilla-reader__menu__button-next')!;
        this._buttonHelp = this._hostDocument.getElementById('vanilla-reader__menu__button-help')!;
        // this._buttonFile = this._hostDocument.getElementById('vanilla-reader__menu__button-file')!;
        this._buttonToc = this._hostDocument.getElementById('vanilla-reader__menu__button-toc')!;
        this._buttonBookmarks = this._hostDocument.getElementById('vanilla-reader__menu__button-bookmarks')!;
        this._buttonHighlights = this._hostDocument.getElementById('vanilla-reader__menu__button-highlights')!;
        this._buttonSettings = this._hostDocument.getElementById('vanilla-reader__menu__button-settings')!;
        this._buttonBookmarkReadingPosition = this._hostDocument.getElementById('vanilla-reader__menu__button-bookmark-readingposition')!;
        this._buttonReadAloudOpen = this._hostDocument.getElementById('vanilla-reader__menu__button-readaloud-show')!;
        this._buttonReadAloudClose = this._hostDocument.getElementById('vanilla-reader__menu__button-readaloud-hide')!;
        this._buttonSearch = this._hostDocument.getElementById('vanilla-reader__menu__button-search')!;
        this._buttonPanZoomActivate = this._hostDocument.getElementById('vanilla-reader__menu__button-panzoom-activate')!;
        this._buttonPanZoomDeactivate = this._hostDocument.getElementById('vanilla-reader__menu__button-panzoom-deactivate')!;
        this._sliderProgression = this._hostDocument.getElementById('vanilla-reader__menu__slider-progression') as HTMLInputElement;
        this._sliderProgressionLabel = this._hostDocument.getElementById('vanilla-reader__menu__slider-progression__label')!;

        this._menu.addEventListener('keyup', this._event_menu_keyup);

        // Ok, we have references to all the menu component's HTML elements. Now let's setup various behaviours such as
        // event listeners.
        this._setup();

    }

    public get sliderProgressionValue(): number {
        return parseInt(this._sliderProgression.value);
    }

    onMenuHandleSwipeDown(callback: VanillaVoidCallback) {
        this._swipeDownCallback = callback;
    }

    onHelpOpen(callback: VanillaVoidCallback) {
        this._openHelpDialogCallback = callback;
    }

    onMenuHandleClicked(callback: VanillaVoidCallback) {
        this._menuHandleClickedCallback = callback;
    }

    onFileOpen(callback: VanillaVoidCallback) {
        this._fileOpenCallback = callback;
    }

    onContentsOpen(callback: VanillaVoidCallback) {
        this._contentsDialogOpenCallback = callback;
    }

    onMenuVisible(callback: VanillaVoidCallback) {
        this._visibleCallback = callback;
    }

    onMenuHidden(callback: VanillaDialogClosedCallback) {
        this._hiddenCallback = callback;
    }

    onBookmarkReadingPosition(callback: VanillaBookmarkReadingPositionIntentEventCallback) {
        this._bookmarkReadingPositionIntentCallback = callback;
    }

    onReadAloudControlsOpen(callback: VanillaVoidCallback) {
        this._showReadAloudControlsCallback = callback;
    }

    onReadAloudControlsClose(callback: VanillaVoidCallback) {
        this._hideReadAloudControlsCallback = callback;
    }

    onPanZoomActivate(callback: VanillaVoidCallback) {
        this._panZoomActivateCallback = callback;
    }

    onPanZoomDeactivate(callback: VanillaVoidCallback) {
        this._panZoomDeactivateCallback = callback;
    }

    onPublicationSearch(callback: VanillaVoidCallback) {
        this._searchPublicationCallback = callback;
    }

    onNavigatePrevious(callback: VanillaUiNavigationIntentEventCallback) {
        this._navigatePreviousCallback = callback;
    }

    onNavigateNext(callback: VanillaUiNavigationIntentEventCallback) {
        this._navigateNextCallback = callback;
    }

    onNavigateToTimelinePosition(callback: VanillaUiNavigationIntentEventCallback) {
        this._navigateToTimelinePositionCallback = callback;
    }

    getComponentElement(): HTMLElement {
        return this._hostDocument.getElementById('vanilla-reader__menu')!;
    }

    getMediaPlayerContainerElement(): HTMLElement {
        return this._hostDocument.getElementById('vanilla-reader__menu__mediaplayer-container')!;
    }

    setAsImportantForAccessibility() {
        this._menu.removeAttribute('aria-hidden');
    }

    setAsNotImportantForAccessibility() {
        this._menu.setAttribute('aria-hidden', 'true');
    }

    public get isVisible(): boolean {
        return this._menu.style.opacity === '1';
    }

    show(animate: boolean = true) {
        if (!animate) {
            this._menu.style.transition = 'opacity 0.2s ease-out';
        } else {
            this._menu.style.transition = 'top 0.2s ease-out';
        }

        this._menu.style.opacity = '1';
        this._menu.style.top = '0';

        this._focusTrap = createFocusTrap(this._menu, {
            allowOutsideClick: true,
            escapeDeactivates: false,
            returnFocusOnDeactivate: true,
        });
        this._focusTrap.activate();

        // Wait until just a bit before turning on events and calling the callback function.
        window.setTimeout(() => {
            this._menu.style.pointerEvents = 'auto';

            if (this._visibleCallback) {
                this._visibleCallback();
            }

        }, 500);

    }

    hide(animate: boolean = true, silent: boolean = false) {
        if (!animate) {
            this._menu.style.transition = 'opacity 0.2s ease-out';
        } else {
            this._menu.style.transition = 'top 0.2s ease-out';
        }

        this._menu.style.pointerEvents = 'none';
        this._menu.style.top = `${-(this._menu.clientHeight + 16)}px`;

        // Wait for the animation to finish before calling callback method etc.
        window.setTimeout(() => {
            this._menu.style.opacity = '0';

            if (this._focusTrap) {

                let options: { returnFocus: boolean } = {
                    returnFocus: false,
                };
                try {
                    this._focusTrap.deactivate(options);
                } catch (e) {

                }
            }

            if (this._hiddenCallback) {
                this._hiddenCallback('menu', silent);
            }
        }, 400);
    }

    initializeTimeline(timelineLength: number) {

        this._timelineLength = timelineLength;

        this._sliderProgression.disabled = false;

        this._sliderProgression.setAttribute('min', '1');
        this._sliderProgression.setAttribute('max', this._timelineLength.toString());
        this._sliderProgression.setAttribute('value', '0');

        this._sliderProgression.addEventListener('change', this._event_menu_sliderProgressionChange);
        this._sliderProgression.addEventListener('input', this._event_sliderProgressionInput);
        console.log('initializeTimeline executed. Timeline length:', timelineLength);

    }

    updateSliderProgressionValue(value: number) {
        let title: string | undefined;
        let progress: number = value / this._timelineLength;

        title = this._getNearestItemFromTimelinePosition(value + 1)?.title;
        title = title ? title : '';
        this._sliderProgression.title = title;
        this._sliderProgression.value = value.toString();
        // this._sliderProgressionLabel.innerText = title + (' (' + Math.round(progress * 100) + '%)');
        // this._sliderProgressionLabel.innerText = Math.ceil((value + 1) / this._timelineLength * this._timelineLength).toString();
        this._sliderProgressionLabel.innerText = `Location ${value + 1} of ${this._timelineLength}`;
    }

    toggleReadAloudButtonState(on: boolean) {
        if (on) {
            this._buttonReadAloudClose.style.display = 'flex';
            this._buttonReadAloudOpen.style.display = 'none';
        } else {
            this._buttonReadAloudOpen.style.display = 'flex';
            this._buttonReadAloudClose.style.display = 'none';
        }

    }

    togglePanZoomButtonState(on: boolean) {
        if (on) {
            this._buttonPanZoomDeactivate.style.display = 'flex';
            this._buttonPanZoomActivate.style.display = 'none';
        } else {
            this._buttonPanZoomActivate.style.display = 'flex';
            this._buttonPanZoomDeactivate.style.display = 'none';
        }

    }

    /*
    *
    * PRIVATE METHODS
    *
    * */

    private _setup() {

        this.hide();

        this._buttonMenuHandle.addEventListener('click', (_ev) => {
            if (this._menuHandleClickedCallback) {
                this._menuHandleClickedCallback();
            }
        });

        this._buttonMenuHandle.addEventListener('touchstart', (ev) => {
            this._touchEventCoords = [ev.changedTouches[0].clientX, ev.changedTouches[0].clientY];
        });

        this._buttonMenuHandle.addEventListener('touchend', (ev) => {
            if (this._touchEventCoords && this._touchEventCoords[1] - ev.changedTouches[0].clientY <= -96) {
                if (this._swipeDownCallback) {
                    this._swipeDownCallback();
                }
            }
        });

        /* this._buttonFile.addEventListener('click', (_ev) => {
            if (this._fileOpenCallback) {
                this._fileOpenCallback();
            }
        }); */

        this._buttonBookmarks.addEventListener('click', (_ev) => {
            if (!this._hostContext.dialogBookmarks?.isOpen()) {
                this._hostContext.dialogBookmarks?.show();
            } else {
                this._hostContext.dialogBookmarks?.close();
            }
        });

        this._buttonHighlights.addEventListener('click', (_ev) => {
            if (!this._hostContext.dialogHighlights?.isOpen()) {
                this._hostContext.dialogHighlights?.show();
            } else {
                this._hostContext.dialogHighlights?.close();
            }
        });

        this._buttonSettings.addEventListener('click', (_ev) => {
            if (this._hostContext.dialogSettings?.isOpen()) {
                this._hostContext.dialogSettings.close();
            } else {
                this._hostContext.dialogSettings?.show();
            }
        });

        this._buttonBookmarkReadingPosition.addEventListener('click', (_ev) => {
            if (this._bookmarkReadingPositionIntentCallback) {
                this._bookmarkReadingPositionIntentCallback();
            }
        });

        this._buttonReadAloudOpen.addEventListener('click', (_ev) => {
            // Toggle the show/hide button-pair state
            this._buttonReadAloudOpen.style.display = 'none';
            this._buttonReadAloudClose.style.display = 'flex';

            if (this._showReadAloudControlsCallback) {
                this._showReadAloudControlsCallback();
            }
        });

        this._buttonReadAloudClose.addEventListener('click', (_ev) => {
            // Toggle the show/hide button-pair state
            this._buttonReadAloudClose.style.display = 'none';
            this._buttonReadAloudOpen.style.display = 'flex';

            if (this._hideReadAloudControlsCallback) {
                this._hideReadAloudControlsCallback();
            }
        });

        this._buttonSearch.addEventListener('click', (_ev) => {
            if (this._searchPublicationCallback) {
                this._searchPublicationCallback();
            }
        });

        this._buttonPrevious.addEventListener('click', (_ev) => {
            if (this._navigatePreviousCallback) {
                this._navigatePreviousCallback({navigationType: VanillaReaderNavigationType.PREVIOUS});
            }
        });

        this._buttonNext.addEventListener('click', (_ev) => {
            if (this._navigateNextCallback) {
                this._navigateNextCallback({navigationType: VanillaReaderNavigationType.NEXT});
            }
        });

        this._buttonHelp.addEventListener('click', (_ev) => {
            if (this._openHelpDialogCallback) {
                this._openHelpDialogCallback();
            }
        });

        this._buttonToc.addEventListener('click', (ev) => {
            // This event must be prevented from propagation to not fire a click
            // on the selected navigation item in the contents dialog, if that
            // item has received focus before the callback has run.

            ev.stopImmediatePropagation();
            ev.preventDefault();
            ev.cancelBubble = true;
            if (this._contentsDialogOpenCallback) {
                this._contentsDialogOpenCallback();
            }
        });

        this._sliderProgression.disabled = true;

        if(this._enablePanZoomButton) {
            this._buttonPanZoomActivate.addEventListener('click', (_ev) => {
                this._buttonPanZoomActivate.style.display = 'none';
                this._buttonPanZoomDeactivate.style.display = 'flex';

                if (this._panZoomActivateCallback) {
                    this._panZoomActivateCallback();
                }
            });

            this._buttonPanZoomDeactivate.addEventListener('click', (_ev) => {
                this._buttonPanZoomDeactivate.style.display = 'none';
                this._buttonPanZoomActivate.style.display = 'flex';

                if (this._panZoomDeactivateCallback) {
                    this._panZoomDeactivateCallback();
                }
            });
        } else {
            this._buttonPanZoomActivate.style.display = 'none';
        }

        this._buttonPanZoomDeactivate.style.display = 'none';

    }

    private _getNearestItemFromTimelinePosition(pos: number): IVanillaReaderNavigationItem | undefined {
        if (this._hostContext.navigationTreeData) {
            let tree = this._hostContext.navigationTreeData;
            if (tree) {
                let currentPos = 0;
                let foundItem: IVanillaReaderNavigationItem | undefined = undefined;

                while (currentPos < tree.items.length) {
                    let currentItem = tree.items[currentPos];
                    if (currentItem.timelineStartPosition == undefined || pos > currentItem.timelineStartPosition) {
                        currentPos++;
                    } else {
                        let itemIndex = currentPos <= 0 ? 0 : currentPos - 1;
                        foundItem = tree.items[itemIndex];
                        break;
                    }
                }
                return foundItem;
            }
        }
        return undefined;
    }

    /*
    *
    * EVENT HANDLERS
    *
    * */

    private _event_menu_keyup = (ev: KeyboardEvent) => {
        switch (ev.key) {
            case 'Escape':
                this.hide();
                break;
        }
    };

    private _event_sliderProgressionInput = (_event: Event) => {
        let sliderValue = parseInt(this._sliderProgression.value);
        if (!isNaN(sliderValue)) {
            this.updateSliderProgressionValue(sliderValue);
        }
    };

    private _event_menu_sliderProgressionChange = (_event: Event) => {

        let sliderPosition = parseInt(this._sliderProgression.value);

        if (this._sliderProgressionChangeTimeout) {
            window.clearTimeout(this._sliderProgressionChangeTimeout);
            this._sliderProgressionChangeTimeout = undefined;
        }

        this._sliderProgressionChangeTimeout = window.setTimeout(() => {

            if (this._navigateToTimelinePositionCallback) {
                this._navigateToTimelinePositionCallback({
                    navigationType: VanillaReaderNavigationType.TIMELINEPOSITION,
                    position: sliderPosition,
                });
            }

        }, 250);

        this.updateSliderProgressionValue(sliderPosition);
    };
}
