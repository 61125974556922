/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IVanillaReaderUI} from "./IVanillaReaderUI";
import {
    IVanillaReaderAppEvent_APP_LOADED,
    IVanillaReaderAppEvent_APP_ONLINE_STATE_CHANGED,
    IVanillaReaderAppEvent_BOOKMARK_ADDED,
    IVanillaReaderAppEvent_BOOKMARK_CLICKED,
    IVanillaReaderAppEvent_BOOKMARK_DELETE_INTENT,
    IVanillaReaderAppEvent_BOOKMARK_DELETED,
    IVanillaReaderAppEvent_BOOKMARK_IN_VISIBLE_RANGE,
    IVanillaReaderAppEvent_BOOKMARK_OUTSIDE_VISIBLE_RANGE,
    IVanillaReaderAppEvent_BOOKMARK_TEXTLOCATION_FAILED,
    IVanillaReaderAppEvent_BOOKMARKS_LOADED,
    IVanillaReaderAppEvent_BOOKMARKS_UPDATED,
    IVanillaReaderAppEvent_CLICK,
    IVanillaReaderAppEvent_DOCUMENT_IS_AT_END,
    IVanillaReaderAppEvent_DOCUMENT_IS_AT_START,
    IVanillaReaderAppEvent_DOCUMENT_LANDMARKS_READY,
    IVanillaReaderAppEvent_HIGHLIGHT_ADDED,
    IVanillaReaderAppEvent_HIGHLIGHT_CLICKED,
    IVanillaReaderAppEvent_HIGHLIGHT_DELETED,
    IVanillaReaderAppEvent_HIGHLIGHT_IN_VISIBLE_RANGE,
    IVanillaReaderAppEvent_HIGHLIGHT_OUTSIDE_VISIBLE_RANGE,
    IVanillaReaderAppEvent_HIGHLIGHT_TEXTLOCATION_FAILED,
    IVanillaReaderAppEvent_HIGHLIGHT_UPDATED,
    IVanillaReaderAppEvent_HIGHLIGHTS_LOADED,
    IVanillaReaderAppEvent_HIGHLIGHTS_UPDATED,
    IVanillaReaderAppEvent_KEYBOARD_EVENT,
    IVanillaReaderAppEvent_MEDIA_OBJECT_VIEW_INTENT,
    IVanillaReaderAppEvent_MEDIAPLAYER_ATTACHED,
    IVanillaReaderAppEvent_MEDIAPLAYER_CREATED,
    IVanillaReaderAppEvent_MEDIAPLAYER_DETACHED,
    IVanillaReaderAppEvent_MEDIAPLAYER_PLAYBACK_POSITION_CHANGED,
    IVanillaReaderAppEvent_MEDIAPLAYER_PLAYBACK_STATE_CHANGED,
    IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_COMPLETED,
    IVanillaReaderAppEvent_NAVIGATION_COMPLETED,
    IVanillaReaderAppEvent_NAVIGATION_EXTERNAL_INTENT,
    IVanillaReaderAppEvent_NAVIGATION_INTERNAL_INTENT,
    IVanillaReaderAppEvent_NAVIGATION_STARTED,
    IVanillaReaderAppEvent_NAVIGATION_TREE_READY,
    IVanillaReaderAppEvent_POINTER_DOWN,
    IVanillaReaderAppEvent_POINTER_UP,
    IVanillaReaderAppEvent_PUBLICATION_DOWNLOAD_PROGRESS_UPDATED,
    IVanillaReaderAppEvent_PUBLICATION_LANDMARKS_READY,
    IVanillaReaderAppEvent_PUBLICATION_LOADED,
    IVanillaReaderAppEvent_PUBLICATION_RENDERED,
    IVanillaReaderAppEvent_PUBLICATION_SEARCH_COMPLETED,
    IVanillaReaderAppEvent_PUBLICATION_SHELF_UPDATED,
    IVanillaReaderAppEvent_PUBLICATION_STYLE_OPTIONS_CHANGED,
    IVanillaReaderAppEvent_READING_POSITION_UPDATED,
    IVanillaReaderAppEvent_REDUCED_MOTION_OPTION_CHANGED,
    IVanillaReaderAppEvent_RENDERER_ASPECT_RATIO_CHANGED,
    IVanillaReaderAppEvent_RENDERER_CHANGED,
    IVanillaReaderAppEvent_TEXT_SELECTED,
    IVanillaReaderAppEvent_TIMELINE_READY,
    IVanillaReaderAppEvent_TIMELINE_UPDATE_INTENT,
    IVanillaReaderAppEvent_VIEW_TRANSFORM_CHANGED,
    IVanillaReaderAppEvent_VIEW_TRANSITION_ENDED,
    IVanillaReaderAppEvent_VIEW_TRANSITION_STARTED,
    IVanillaReaderAppEvent_VISIBLE_RANGE_DATA_CHANGED,
    VanillaReaderAppEvents,
    VanillaReaderEventBus,
} from "../VanillaReader/VanillaReaderEventBus";
import {IAttributeData} from "@colibrio/colibrio-reader-framework/colibrio-core-base";
import {
    EngineEventTargetFrameworkComponent,
    ReaderDocumentEventState
} from "@colibrio/colibrio-reader-framework/colibrio-readingsystem-base";
import {IVanillaReaderReadingPositionData, VanillaReaderRendererNames} from "../VanillaReader/VanillaReaderModel";
import {ContentBlockDataHtmlRenderer} from "../utils/ContentBlockDataHtmlRenderer";
import {IVanillaReaderUiOptionsData} from "./VanillaReaderUiModel";
import {VanillaReaderUiKeyboardShortcutController} from "./VanillaReaderUiKeyboardShortcutController";
import {VanillaReaderUiMediaSessionsController} from "./VanillaReaderUiMediaSessionsController";
import {ICoordinates} from "../utils/ICoordinates";

export interface VanillaReaderUiVanillaReaderEventController extends IVanillaReaderUI, VanillaReaderUiKeyboardShortcutController, VanillaReaderUiMediaSessionsController {}

/**
 * # VanillaReaderUiVanillaReaderEventController
 *
 * ## RESPONSIBILITIES
 * This controller mixing class handles all events sent from the `VanillaReader` class. Because it handles all incoming
 * the other UI classes, such as dialogs, are called in this class.
 *
 * Lots of fun and interesting stuff to see here :)
 *
 * Just as all other controller mixin classes, this class is used to add functionality to the `VanillaReaderUi` class
 * in a "modular" way. Read more about this in the `UI Components and Controller Mixins` in the README.md file.
 *
 * ## NOTE ON THE EVENT SYSTEM
 *
 * To keep a clear separation between the application logic in VanillaReader.ts and the GUI element logic
 * in this file, all communication between these two "components" are done using the VanillaReaderEventBus.ts.
 *
 * When the GUI has something it wants the VanillaReader (and the Colibrio Reader Framework) to do, it dispatches
 * a CustomEvent using the event bus. Likewise, when the VanillaReader updates its state it will dispatch an event
 * that tells the UI what data that has been changed.
 *
 * The general convention is that the VanillaReaderUI sends "intentions" for the VanillaReader to performs actions,
 * and that the VanillaReader responds with updated data.
 *
 *
 *
 *      |-----------------------|                   |-----------------------|
 *      |                       |   -- intent ->    |                       |
 *      |   VanillaReaderUI     |                   |     VanillaReader     |
 *      |                       |   <- update --    |                       |
 *      |-----------------------|                   |-----------------------|
 *
 * ## RELATED TYPES
 * - VanillaReaderUi
 * - VanillaReaderEventBus
 * - VanillaReaderAppEvents
 *
 */
export class VanillaReaderUiVanillaReaderEventController {

    _addVanillaReaderEventListeners() {

        /*
        *
        * Here are all the event listeners. Almost all events are fired by the `VanillaReader` instance in response
        * to a change in its application state.
        *
        * The naming is quite intuitive I hope :)
        *
        * */
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_APP_LOADED>(VanillaReaderAppEvents.APP_LOADED, this._vanillaReaderEvent_appLoaded.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_APP_ONLINE_STATE_CHANGED>(VanillaReaderAppEvents.APP_ONLINE_STATE_CHANGED, this._vanillaReaderEvent_appOnlineStateChanged.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_BOOKMARKS_LOADED>(VanillaReaderAppEvents.BOOKMARKS_LOADED, this._vanillaReaderEvent_bookmarksLoaded.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_BOOKMARK_ADDED>(VanillaReaderAppEvents.BOOKMARK_ADDED, this._vanillaReaderEvent_bookmarkAdded.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_BOOKMARK_ADDED>(VanillaReaderAppEvents.BOOKMARK_CLICKED, this._vanillaReaderEvent_bookmarkClicked.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_BOOKMARK_DELETED>(VanillaReaderAppEvents.BOOKMARK_DELETED, this._vanillaReaderEvent_bookmarkDeleted.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_BOOKMARK_TEXTLOCATION_FAILED>(VanillaReaderAppEvents.BOOKMARK_TEXTLOCATION_FAILED, this._vanillaReaderEvent_bookmarkTextLocationFailed.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_BOOKMARKS_UPDATED>(VanillaReaderAppEvents.BOOKMARKS_COLLECTION_UPDATED, this._vanillaReaderEvent_bookmarksCollectionUpdated.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_BOOKMARK_IN_VISIBLE_RANGE>(VanillaReaderAppEvents.BOOKMARK_IN_VISIBLE_RANGE, this._vanillaReaderEvent_bookmarkInVisibleRange.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_BOOKMARK_OUTSIDE_VISIBLE_RANGE>(VanillaReaderAppEvents.BOOKMARK_OUTSIDE_VISIBLE_RANGE, this._vanillaReaderEvent_bookmarkOutsideVisibleRange.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_DOCUMENT_IS_AT_START>(VanillaReaderAppEvents.DOCUMENT_IS_AT_START, this._vanillaReaderEvent_documentIsAtStart.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_DOCUMENT_IS_AT_END>(VanillaReaderAppEvents.DOCUMENT_IS_AT_END, this._vanillaReaderEvent_documentIsAtEnd.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_HIGHLIGHTS_LOADED>(VanillaReaderAppEvents.HIGHLIGHTS_LOADED, this._vanillaReaderEvent_highlightsLoaded.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_HIGHLIGHT_ADDED>(VanillaReaderAppEvents.HIGHLIGHT_ADDED, this._vanillaReaderEvent_highlightAdded.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_HIGHLIGHT_DELETED>(VanillaReaderAppEvents.HIGHLIGHT_DELETED, this._vanillaReaderEvent_highlightDeleted.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_HIGHLIGHT_UPDATED>(VanillaReaderAppEvents.HIGHLIGHT_UPDATED, this._vanillaReaderEvent_highlightUpdated.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_HIGHLIGHTS_UPDATED>(VanillaReaderAppEvents.HIGHLIGHTS_COLLECTION_UPDATED, this._vanillaReaderEvent_highlightsCollectionUpdated.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_HIGHLIGHT_CLICKED>(VanillaReaderAppEvents.HIGHLIGHT_CLICKED, this._vanillaReaderEvent_highlightClicked.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_HIGHLIGHT_TEXTLOCATION_FAILED>(VanillaReaderAppEvents.HIGHLIGHT_TEXTLOCATION_FAILED, this._vanillaReaderEvent_highlightTextLocationFailed.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_HIGHLIGHT_IN_VISIBLE_RANGE>(VanillaReaderAppEvents.HIGHLIGHT_IN_VISIBLE_RANGE, this._vanillaReaderEvent_highlightInVisibleRange.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_HIGHLIGHT_OUTSIDE_VISIBLE_RANGE>(VanillaReaderAppEvents.HIGHLIGHT_OUTSIDE_VISIBLE_RANGE, this._vanillaReaderEvent_highlightOutsideVisibleRange.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_KEYBOARD_EVENT>(VanillaReaderAppEvents.KEY_UP, this._vanillaReaderEvent_readerKeyUp.bind(this));
        // This is one of the few intents that are sent from the `VanillaReader`. This is because the event (probably a
        // pointer up on an image element) is emitted by the Colibrio `ReaderView`. So an intent is sent to the UI so that
        // it can show the media resource in a popup dialog.
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_MEDIA_OBJECT_VIEW_INTENT>(VanillaReaderAppEvents.MEDIA_OBJECT_VIEW_INTENT, this._vanillaReaderEvent_mediaObjectViewIntent.bind(this));

        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_MEDIAPLAYER_CREATED>(VanillaReaderAppEvents.MEDIAPLAYER_CREATED, this._vanillaReaderEvent_mediaPlayerCreated.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_MEDIAPLAYER_PLAYBACK_STATE_CHANGED>(VanillaReaderAppEvents.MEDIAPLAYER_PLAYBACK_STATE_CHANGED, this._vanillaReaderEvent_mediaPlayerPlaybackStateChanged.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_MEDIAPLAYER_PLAYBACK_POSITION_CHANGED>(VanillaReaderAppEvents.MEDIAPLAYER_PLAYBACK_POSITION_CHANGED, this._vanillaReaderEvent_mediaPlayerPlaybackPositionChanged.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_COMPLETED>(VanillaReaderAppEvents.MEDIAPLAYER_SEEK_COMPLETED, this._vanillaReaderEvent_mediaPlayerSeekCompleted.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_MEDIAPLAYER_ATTACHED>(VanillaReaderAppEvents.MEDIAPLAYER_ATTACHED, this._vanillaReaderEvent_mediaPlayerAttached.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_MEDIAPLAYER_DETACHED>(VanillaReaderAppEvents.MEDIAPLAYER_DETACHED, this._vanillaReaderEvent_mediaPlayerDetached.bind(this));
        // This is one of the few intents that are sent from the `VanillaReader`. A link with a target outside the publication
        // has been triggered in the Colibrio `IReaderView`. An intent is sent to the UI in order for it to decide how
        // to give the appropriate options to the user.
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_NAVIGATION_INTERNAL_INTENT>(VanillaReaderAppEvents.NAVIGATION_INTERNAL_INTENT, this._vanillaReaderEvent_navigationGotoInternalLocationIntent.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_NAVIGATION_EXTERNAL_INTENT>(VanillaReaderAppEvents.NAVIGATION_EXTERNAL_INTENT, this._vanillaReaderEvent_navigationOpenRemoteAddressIntent.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_NAVIGATION_STARTED>(VanillaReaderAppEvents.NAVIGATION_STARTED, this._vanillaReaderEvent_navigationStarted.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_NAVIGATION_COMPLETED>(VanillaReaderAppEvents.NAVIGATION_COMPLETED, this._vanillaReaderEvent_navigationCompleted.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_NAVIGATION_TREE_READY>(VanillaReaderAppEvents.NAVIGATION_TREE_READY, this._vanillaReaderEvent_navigationTreeReady.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_POINTER_DOWN>(VanillaReaderAppEvents.POINTER_DOWN, this._vanillaReaderEvent_readerViewPointerDown.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_POINTER_UP>(VanillaReaderAppEvents.POINTER_UP, this._vanillaReaderEvent_readerViewPointerUp.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_POINTER_UP>(VanillaReaderAppEvents.CLICK, this._vanillaReaderEvent_click.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_PUBLICATION_LOADED>(VanillaReaderAppEvents.PUBLICATION_LOADED, this._vanillaReaderEvent_publicationLoaded.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_PUBLICATION_RENDERED>(VanillaReaderAppEvents.PUBLICATION_RENDERED, this._vanillaReaderEvent_publicationRendered.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_PUBLICATION_DOWNLOAD_PROGRESS_UPDATED>(VanillaReaderAppEvents.PUBLICATION_DOWNLOAD_PROGRESS_UPDATED, this._vanillaReaderEvent_publicationDownloadProgressUpdated.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_PUBLICATION_SEARCH_COMPLETED>(VanillaReaderAppEvents.PUBLICATION_SEARCH_COMPLETED, this._vanillaReaderEvent_publicationSearchCompleted.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_PUBLICATION_SHELF_UPDATED>(VanillaReaderAppEvents.PUBLICATION_SHELF_UPDATED, this._appEvent_publicationShelfUpdated.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_PUBLICATION_STYLE_OPTIONS_CHANGED>(VanillaReaderAppEvents.PUBLICATION_STYLE_OPTIONS_CHANGED, this._vanillaReaderEvent_publicationStyleOptionsChanged.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_RENDERER_CHANGED>(VanillaReaderAppEvents.RENDERER_CHANGED, this._vanillaReaderEvent_rendererChanged.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_RENDERER_ASPECT_RATIO_CHANGED>(VanillaReaderAppEvents.RENDERER_ASPECT_RATIO_CHANGED, this._vanillaReaderEvent_rendererAspectRatioChanged.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_REDUCED_MOTION_OPTION_CHANGED>(VanillaReaderAppEvents.REDUCED_MOTION_OPTION_CHANGED, this._vanillaReaderEvent_reducedMotionOptionChanged.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_READING_POSITION_UPDATED>(VanillaReaderAppEvents.READING_POSITION_UPDATED, this._vanillaReaderEvent_readingPositionUpdated.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_TEXT_SELECTED>(VanillaReaderAppEvents.TEXT_SELECTED, this._vanillaReaderEvent_textSelected.bind(this));
        // This is one of the few intents that are sent from the `VanillaReader`. This is dispatched when a navigation
        // action has occurred in the Colibrio `IReaderView` that has changed the timeline position. Thanks to this intent,
        // the UI can now update its reading progression slider accordingly.
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_TIMELINE_UPDATE_INTENT>(VanillaReaderAppEvents.TIMELINE_UPDATE_INTENT, this._vanillaReaderEvent_timelineUpdateIntent.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_VISIBLE_RANGE_DATA_CHANGED>(VanillaReaderAppEvents.VISIBLE_RANGE_DATA_CHANGED, this._vanillaReaderEvent_visibleRangeDataChanged.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_TIMELINE_READY>(VanillaReaderAppEvents.TIMELINE_READY, this._vanillaReaderEvent_timelineReady.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_VIEW_TRANSITION_STARTED>(VanillaReaderAppEvents.VIEW_TRANSITION_STARTED, this._vanillaReaderEvent_viewTransitionStarted.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_VIEW_TRANSITION_ENDED>(VanillaReaderAppEvents.VIEW_TRANSITION_ENDED, this._vanillaReaderEvent_viewTransitionEnded.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_VIEW_TRANSFORM_CHANGED>(VanillaReaderAppEvents.VIEW_TRANSFORM_CHANGED, this._vanillaReaderEvent_viewTransformChanged.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_PUBLICATION_LANDMARKS_READY>(VanillaReaderAppEvents.PUBLICATION_LANDMARKS_READY, this._vanillaReaderEvent_publicationLandmarksReady.bind(this));
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_DOCUMENT_LANDMARKS_READY>(VanillaReaderAppEvents.DOCUMENT_LANDMARKS_READY, this._vanillaReaderEvent_documentLandmarksReady.bind(this));
    }

    _vanillaReaderEvent_appLoaded(_ev: CustomEvent<IVanillaReaderAppEvent_APP_LOADED>) {
        console.log('_vanillaReaderEvent_appLoaded');
    };

    _vanillaReaderEvent_appOnlineStateChanged(ev: CustomEvent<IVanillaReaderAppEvent_APP_ONLINE_STATE_CHANGED>) {
        let toastMsg = ev.detail.isOnline ? 'App running online' : 'App running offline';

        this.toaster.toast(toastMsg).catch(console.error);

        this.dialogOpenFile.isOffline = ev.detail.isOnline;
    };

    _vanillaReaderEvent_readerViewPointerDown(ev: CustomEvent<IVanillaReaderAppEvent_POINTER_DOWN>) {
        this.latestReaderViewPointerDownEventCoords = {
            x: ev.detail.srcEventData.clientX,
            y: ev.detail.srcEventData.clientY,
        };
    };

    /*
    *
    * This event handler takes care of deciding what to do when a pointer up has been released. In an EPUB especially
    * there are a lot of things that need to be handled correctly for the user experience to be good. Especially in
    * EPUBs that are scripted.
    *
    * In the Imbiblio Reader this is extra important since it uses the familiar "click the publication to open the
    * main menu"-feature. There are quite a number of instances where you *don't* want to open the menu, such as if the
    * user,
    *
    * - clicks a HTML Form control
    * - clicks an image
    * - a details element
    * - etc
    *
    * */
    _vanillaReaderEvent_readerViewPointerUp(ev: CustomEvent<IVanillaReaderAppEvent_POINTER_UP>) {
        let srcEventData = ev.detail.srcEventData;
        let ignoreEvent: boolean = false;

        this.latestReaderViewPointerUpEventCoords = {
            x: ev.detail.srcEventData.clientX,
            y: ev.detail.srcEventData.clientY,
        };

        // Check if the pointer event is an engine event. If not we can not reference any Colibrio specific data.
        if (this._eventDataIsPointerEngineEventData(srcEventData)) {

            let hasMediaPlayerReadAloudAttribute = srcEventData.target?.nodeData?.attributes.some((attribute: IAttributeData) => {
                return attribute.localName === 'ibooks-readaloud' || attribute.localName === 'readaloud';
            });

            if (hasMediaPlayerReadAloudAttribute) {
                this.startMediaPlayer(srcEventData.target?.locator?.selectors[0] || undefined);
            }

            if (srcEventData.mediaResource) {
                // Clicks on images are handled in the `event_mediaObjectViewIntent` method, so we can return.
                return;
            }

            let eventTarget = srcEventData.target;

            // Filter out other elements where we should not interfere in the original event behaviour
            switch (eventTarget?.nodeData?.nodeName.toLocaleLowerCase()) {
                case 'a':
                case 'figure':
                case 'img':
                case 'details':
                case 'summary':
                case 'button':
                case 'select':
                case 'input':
                case 'textarea':
                    ignoreEvent = true;
                    break;
                default:
            }

            let targetIsBookmarkOrHighlight = eventTarget?.frameworkComponent === EngineEventTargetFrameworkComponent.READER_VIEW_ANNOTATION;

            if (targetIsBookmarkOrHighlight || this.activeSelection) {
                ignoreEvent = true;
            }

            if (srcEventData.readerDocumentEventState === ReaderDocumentEventState.DEFAULT_PREVENTED) {
                ignoreEvent = true;
            }

            // If the reader is in accessibility mode we show a dialog with actions instead of the bottom menu.
            // This is done in the click event handler
            if(this.isInAccessibilityMode && !this.activeSelection) {
                ignoreEvent = true;
            }
        }

        // If the media player is playing we should not open the menu as clicking the book page also moves the reading
        // position.
        if (this.menuMediaPlayer.stateIsPlaying && !this.initialPublicationData?.isAudiobook) {
            ignoreEvent = true;
        }

        if (!this.latestReaderViewPointerDownEventCoords) {
            // If, for some strange reason, we do not have data about the pointer down event.
            this.latestReaderViewPointerDownEventCoords = this.latestReaderViewPointerUpEventCoords;
        }

        let pointerEventDeltas: ICoordinates = {
            x: Math.abs(this.latestReaderViewPointerDownEventCoords.x - this.latestReaderViewPointerUpEventCoords.x),
            y: Math.abs(this.latestReaderViewPointerDownEventCoords.y - this.latestReaderViewPointerUpEventCoords.y),
        };

        if (ignoreEvent || pointerEventDeltas.x > 20 || pointerEventDeltas.y > 20) {
            return;
        } else {
            ev.preventDefault();

            if (this.menuBottom.isVisible) {
                this.menuBottom.hide(!this.useReducedMotion);
            } else {
                this.menuBottom.show(!this.useReducedMotion);
            }
        }
    };

    /*
    *
    *
    * */
    _vanillaReaderEvent_click(ev: CustomEvent<IVanillaReaderAppEvent_CLICK>) {
        let srcEventData = ev.detail.srcEventData;


        // Check if the pointer event is an engine event. If not we can not reference any Colibrio specific data.
        if (this._eventDataIsMouseEngineEventData(srcEventData)) {

            if(srcEventData.defaultPrevented || srcEventData.readerDocumentEventState === ReaderDocumentEventState.DEFAULT_PREVENTED) {
                return;
            }

            // If the reader is in accessibility mode we show a dialog with actions instead of the bottom menu.
            if (this.isInAccessibilityMode && !this.activeSelection) {
                let eventTarget = srcEventData.target;

                if (eventTarget?.locator) {
                    this.navigateTo(`#${eventTarget.locator.selectors[0]}`, false);
                    ev.preventDefault();
                    ev.stopImmediatePropagation();
                    this.dialogUiActions.show(true, true, undefined,true);
                }
            }
        }
    }

    /*
    *
    * This event handler is called when the `VanillaReader` has sent the `PUBLICATION_LOADED`. This means that the
    * rendering has started. There is also a `PUBLICATION_RENDERED` that you can listen to if you want to know when the
    * first page has been rendered.
    *
    * */
    _vanillaReaderEvent_publicationLoaded(ev: CustomEvent<IVanillaReaderAppEvent_PUBLICATION_LOADED>){

        // If we had no publication data stored in the data store, we populate the `_initialPublicationData`
        // variable with the data sent in the event data.
        if (!this.initialPublicationData) {
            this.initialPublicationData = ev.detail.publicationData;
        }

        if (!this.initialPublicationOptionsData) {
            this.initialPublicationOptionsData = ev.detail.publicationOptionsData;
            if(this.initialPublicationOptionsData?.ttsOptions?.voiceName) {
                this.dialogSettings.setSelectedTtsVoiceOption(this.initialPublicationOptionsData.ttsOptions.voiceName);
            }
        }

        if (this._publicationOpenedCallback) {
            this._publicationOpenedCallback(this.initialPublicationData);
        }

        this._onAfterPublicationLoadedSetup(ev.detail.publicationData);

        if (this.dialogOpenFile.isOpen()) {
            this.dialogOpenFile.close(true);
        }
    };

    _vanillaReaderEvent_publicationRendered(_ev: CustomEvent<IVanillaReaderAppEvent_PUBLICATION_RENDERED>) {
    };

    /*
    *
    * This method is called multiple times as a publication is being downloaded for offline reading.
    * The `progress` value is a number between 0 and 1.
    *
    * */
    _vanillaReaderEvent_publicationDownloadProgressUpdated(ev: CustomEvent<IVanillaReaderAppEvent_PUBLICATION_DOWNLOAD_PROGRESS_UPDATED>) {
        if (ev.detail.progress === 1) {
            this._onPublicationDownloaded(ev.detail.publicationId);
        } else {
            this._onPublicationDownloadProgressUpdated(ev.detail.publicationId, ev.detail.progress);
        }
    };

    _vanillaReaderEvent_highlightsLoaded(ev: CustomEvent<IVanillaReaderAppEvent_HIGHLIGHTS_LOADED>) {
        this.dialogHighlights?.populate(ev.detail.highlights || []);
    };

    _vanillaReaderEvent_highlightTextLocationFailed(ev: CustomEvent<IVanillaReaderAppEvent_HIGHLIGHT_TEXTLOCATION_FAILED>) {
        this.toaster.toast(`Failed to add highlight for text "${ev.detail.text}"`, true).catch(console.warn);
    };

    _vanillaReaderEvent_highlightClicked(ev: CustomEvent<IVanillaReaderAppEvent_HIGHLIGHT_CLICKED>) {
        let highlight = ev.detail.highlight;
        this.dialogHighlight?.setState(highlight, false);
        this.dialogHighlight?.show();
    };

    async _vanillaReaderEvent_highlightInVisibleRange(_ev: CustomEvent<IVanillaReaderAppEvent_HIGHLIGHT_IN_VISIBLE_RANGE>) {
        await this.toaster.toast('Visible content has highlights. Press Alt + Shift + H to list them.', true);
    };

    _vanillaReaderEvent_highlightOutsideVisibleRange(_ev: CustomEvent<IVanillaReaderAppEvent_HIGHLIGHT_OUTSIDE_VISIBLE_RANGE>) {
    };

    _vanillaReaderEvent_highlightAdded(ev: CustomEvent<IVanillaReaderAppEvent_HIGHLIGHT_ADDED>) {

        // Add the new bookmark to the list of selected items so that it can be filtered out as being visible.
        let selectedHighlights = this.dialogHighlights.selectedItems || [];
        console.log("this.dialogHighlights.selectedItems", this.dialogHighlights.selectedItems);
        selectedHighlights.push(ev.detail.highlight);
        this.dialogHighlights.setSelected(selectedHighlights);

        this.navigateTo(ev.detail.highlight.locator, true);

        this.toaster.toast('Highlight added', true).catch(console.error);
    };

    _vanillaReaderEvent_highlightDeleted(_ev: CustomEvent<IVanillaReaderAppEvent_HIGHLIGHT_DELETED>) {
        this.toaster.toast('Highlight deleted', true).catch(console.error);
    };

    _vanillaReaderEvent_highlightUpdated(_ev: CustomEvent<IVanillaReaderAppEvent_HIGHLIGHT_UPDATED>) {
    };

    _vanillaReaderEvent_highlightsCollectionUpdated(ev: CustomEvent<IVanillaReaderAppEvent_HIGHLIGHTS_UPDATED>) {
        this.dialogHighlights?.populate(ev.detail.highlights);
    };

    _vanillaReaderEvent_bookmarksLoaded(ev: CustomEvent<IVanillaReaderAppEvent_BOOKMARKS_LOADED>) {
        this.dialogBookmarks?.populate(ev.detail.bookmarks || []);
    };

    _vanillaReaderEvent_bookmarkAdded(ev: CustomEvent<IVanillaReaderAppEvent_BOOKMARK_ADDED>) {
        // Add the new bookmark to the list of selected items so that it can be filtered out as being visible.
        let selectedBookmarks = this.dialogBookmarks.selectedItems || [];
        selectedBookmarks.push(ev.detail.bookmark);
        this.dialogBookmarks.setSelected(selectedBookmarks);

        this.navigateTo(ev.detail.bookmark.locator, true);

        this.toaster.toast('Bookmark added', true).catch(console.error);
    };

    _vanillaReaderEvent_bookmarkClicked(ev: CustomEvent<IVanillaReaderAppEvent_BOOKMARK_CLICKED>) {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_BOOKMARK_DELETE_INTENT>(new CustomEvent<IVanillaReaderAppEvent_BOOKMARK_DELETE_INTENT>(VanillaReaderAppEvents.BOOKMARK_DELETE_INTENT, {detail: {bookmark: ev.detail.bookmark}}));
    };

    _vanillaReaderEvent_bookmarkDeleted(_ev: CustomEvent<IVanillaReaderAppEvent_BOOKMARK_DELETED>) {
        this.toaster.toast('Bookmark deleted', true).catch(console.error);
    };

    _vanillaReaderEvent_bookmarkTextLocationFailed(ev: CustomEvent<IVanillaReaderAppEvent_BOOKMARK_TEXTLOCATION_FAILED>) {
        this.toaster.toast(`Failed to add bookmark for text "${ev.detail.text}"`, true).catch(console.error);
    };

    _vanillaReaderEvent_bookmarksCollectionUpdated(ev: CustomEvent<IVanillaReaderAppEvent_BOOKMARKS_UPDATED>) {
        this.dialogBookmarks?.populate(ev.detail.bookmarks);
    };

    async _vanillaReaderEvent_bookmarkInVisibleRange(_ev: CustomEvent<IVanillaReaderAppEvent_BOOKMARK_IN_VISIBLE_RANGE>) {
        await this.toaster.toast('Visible content has bookmarks. Press Alt + Shift + O to list them.', true);
    };

    _vanillaReaderEvent_bookmarkOutsideVisibleRange(_ev: CustomEvent<IVanillaReaderAppEvent_BOOKMARK_OUTSIDE_VISIBLE_RANGE>) {};

    _vanillaReaderEvent_documentIsAtStart(_ev: CustomEvent<IVanillaReaderAppEvent_DOCUMENT_IS_AT_START>) {
        if (this.dialogSettings.activeRendererTypeName === VanillaReaderRendererNames.SCROLL) {
            console.log('_vanillaReaderEvent_documentIsAtStart');
        }
    };

    async _vanillaReaderEvent_documentIsAtEnd(_ev: CustomEvent<IVanillaReaderAppEvent_DOCUMENT_IS_AT_END>) {
        if (this.dialogSettings.activeRendererTypeName === VanillaReaderRendererNames.SCROLL) {
            await this.toaster.toast('Book section is at end.', true);
        }
    };

    _vanillaReaderEvent_mediaPlayerAttached(_ev: CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_ATTACHED>) {
        if (!this.menuMediaPlayer?.isOpen()) {
            this.menuMediaPlayer.open();
            // Refresh so that the ReaderView can adapt to the new dimensions
            VanillaReaderEventBus.dispatchEvent(new CustomEvent(VanillaReaderAppEvents.VIEW_REFRESH_INTENT));
        }
    };

    _vanillaReaderEvent_mediaPlayerDetached(_ev: CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_DETACHED>) {
        if (this.menuMediaPlayer.isOpen()) {
            this.menuMediaPlayer?.close();
        }
        // Refresh so that the ReaderView can adapt to the new dimensions
        VanillaReaderEventBus.dispatchEvent(new CustomEvent(VanillaReaderAppEvents.VIEW_REFRESH_INTENT));
    };

    _vanillaReaderEvent_mediaObjectViewIntent(ev: CustomEvent<IVanillaReaderAppEvent_MEDIA_OBJECT_VIEW_INTENT>) {

        // No image dialog if the view is in pan zoom mode
        if(this.isReaderViewIsInPanZoomMode === true) {
            return;
        }

        if (ev.detail.mediaUrl) {
            let description: string | undefined;
            if(ev.detail.mediaElementAttributes && ev.detail.mediaElementAttributes.length > 0) {
                description = ev.detail.mediaElementAttributes.find(attribute => attribute.localName === 'alt')?.value;
            }

            this.dialogImageViewer?.loadImage(ev.detail.mediaUrl, description);
            this.dialogImageViewer.show(false, true, this._onDialogImageViewerDialogClosed);
        } else {
            console.warn('_vanillaReaderEvent_mediaObjectViewIntent(): mediaUrl event data was undefined.');
        }
    };

    _vanillaReaderEvent_navigationOpenRemoteAddressIntent(ev: CustomEvent<IVanillaReaderAppEvent_NAVIGATION_EXTERNAL_INTENT>) {
        let url = ev.detail.targetUrl;

        // Do not open urls that are local to the "com.colibrio" scope.
        if (url && !url.includes('com.colibrio')) {
            if (window.confirm('Do you wish to open "' + url + '"?')) {
                window.open(url);
            }
        }
    };


    /*
    *
    * This method is called when a navigation within the publication content has been triggered.
    * There are some specific cases where you will not simply navigate to the location, such if you click on a footnote.
    * In the code below a popup is shown when the user clicks an HTML link to a footnote.
    *
    * */
    _vanillaReaderEvent_navigationGotoInternalLocationIntent(ev: CustomEvent<IVanillaReaderAppEvent_NAVIGATION_INTERNAL_INTENT>) {
        if (ev.detail.srcEvent?.relatedEvent?.target) {

            let nodeData = ev.detail.srcEvent.relatedEvent.target.nodeData;
            let srcEvent = ev.detail.srcEvent;

            if (nodeData) {

                // Let's check if this is a noteref element
                let isNoteRef = nodeData.attributes.find((attribute) => {
                    return attribute.value === 'noteref';
                });

                if (isNoteRef) {
                    ev.preventDefault();

                    let noteRefLocator = ev.detail.srcEvent.relatedEvent.target.contentLocation?.getLocator().toString();

                    let internalHref = nodeData.attributes.find((attribute) => {
                        return attribute.localName === 'href';
                    });

                    if (!internalHref) {
                        console.warn('_vanillaReaderEvent_navigationGotoInternalLocationIntent, link has no "href" attribute. ');
                        return;
                    }

                    let id = internalHref.value.slice(internalHref.value.indexOf('#') + 1);
                    let footnoteContentBlock = this.getFootnoteLandmarkByElementId(id);
                    if (footnoteContentBlock && srcEvent.locator) {

                        let footerHtmlContent = new ContentBlockDataHtmlRenderer().rendererToString([footnoteContentBlock]);

                        this.dialogFootnote.show(false, true, this._onDialogFootnoteClosed.bind(this), footerHtmlContent, srcEvent.locator.toString(), noteRefLocator);
                    } else {
                        console.warn(`_vanillaReaderEvent_navigationGotoInternalLocationIntent, could not find a footnote with id "${id}".`);
                    }

                } else if (ev.detail.srcEvent && ev.detail.srcEvent.locator) {
                    // Not a noteref so we can do an internal navigation as usual.
                    ev.preventDefault();
                    let locator = ev.detail.srcEvent.locator.toString();
                    this.navigateTo(locator);
                }

            } else if (ev.detail.srcEvent && ev.detail.srcEvent.locator) {
                // Probably a PDF since there is no nodeData.
                ev.preventDefault();
                let locator = ev.detail.srcEvent.locator.toString();
                this.navigateTo(locator);
            }

        } else {
            console.warn('_vanillaReaderEvent_navigationGotoInternalLocationIntent, got invalid locator. ');
        }
    };

    _vanillaReaderEvent_navigationStarted(_ev: CustomEvent<IVanillaReaderAppEvent_NAVIGATION_STARTED>) {
        this.isReaderViewNavigating = true;
    };

    _vanillaReaderEvent_navigationCompleted(_ev: CustomEvent<IVanillaReaderAppEvent_NAVIGATION_COMPLETED>) {
        this.isReaderViewNavigating = false;
        if (this.menuBottom.isVisible) {
            this.menuBottom.hide(!this.useReducedMotion);
        }
    };

    _vanillaReaderEvent_publicationSearchCompleted(ev: CustomEvent<IVanillaReaderAppEvent_PUBLICATION_SEARCH_COMPLETED>) {
        if (ev.detail.resultSet) {
            this.dialogSearch?.populate(ev.detail.resultSet);
            this.dialogSearch?.show();
            this.toaster.toast(`Search returned ${ev.detail.resultSet.length} results.`, true).catch(console.warn);
        } else {
            this.toaster.toast(`Search returned no results.`, true).catch(console.warn);
        }
    };

    _appEvent_publicationShelfUpdated(ev: CustomEvent<IVanillaReaderAppEvent_PUBLICATION_SHELF_UPDATED>) {
        this.dialogOpenFile.updateShelfItems(ev.detail.shelfItems);
    };

    _vanillaReaderEvent_publicationStyleOptionsChanged(ev: CustomEvent<IVanillaReaderAppEvent_PUBLICATION_STYLE_OPTIONS_CHANGED>) {
        let appUiSettings: IVanillaReaderUiOptionsData = {
            publicationStyleOptions: ev.detail.styleOptions,
        };
        this.dialogSettings.setViewState(appUiSettings);

    };

    _vanillaReaderEvent_timelineUpdateIntent(ev: CustomEvent<IVanillaReaderAppEvent_TIMELINE_UPDATE_INTENT>) {
        let position = ev.detail.timelinePosition;
        if (position !== undefined && position >= 0) {
            this.menuBottom?.updateSliderProgressionValue(position);
            this.latestTimelinePosition = position;
        } else {
            console.warn(`VanillaReaderUI._vanillaReaderEvent_timelineUpdateIntent(): timelinePosition value ${position} is not a valid postion.`);
        }
    };

    _vanillaReaderEvent_rendererChanged(ev: CustomEvent<IVanillaReaderAppEvent_RENDERER_CHANGED>) {

        // If the renderer is set to VanillaReaderRendererNames.RESPONSIVE we do not need to update the select box since.
        if (ev.detail.newActiveRendererName && this.dialogSettings.selectedRendererTypeName !== VanillaReaderRendererNames.RESPONSIVE) {
            this.dialogSettings?.setSelectedRendererOption(ev.detail.newActiveRendererName);
        }

        // If the renderer is set to VanillaReaderRendererNames.RESPONSIVE we let the currently selected renderer decide
        // the `ignoreAspectRatio` setting.
        if (ev.detail.newActiveRendererOptions && this.dialogSettings.selectedRendererTypeName === VanillaReaderRendererNames.RESPONSIVE) {
            this.dialogSettings.setViewState({
                ignoreAspectRatio: ev.detail.newActiveRendererOptions.ignoreAspectRatio || false,
            });
        }

    };

    _vanillaReaderEvent_rendererAspectRatioChanged(ev: CustomEvent<IVanillaReaderAppEvent_RENDERER_ASPECT_RATIO_CHANGED>) {
        this.dialogSettings?.setRendererAspectRatioOption(ev.detail.ignoreAspectRatio);
    };

    _vanillaReaderEvent_reducedMotionOptionChanged(ev: CustomEvent<IVanillaReaderAppEvent_REDUCED_MOTION_OPTION_CHANGED>) {
        this.useReducedMotion = ev.detail.useReducedMotion;
        this.dialogSettings?.setUseReducedMotionOption(ev.detail.useReducedMotion);
    };

    _vanillaReaderEvent_readingPositionUpdated(ev: CustomEvent<IVanillaReaderAppEvent_READING_POSITION_UPDATED>) {
        // Only focus on reading position if there is no dialog open, and that the scroll renderer is not used.

        this.latestReadingPosition = ev.detail.readingPositionData;

        if (!this.activeDialogTitle && this.dialogSettings.activeRendererTypeName !== VanillaReaderRendererNames.SCROLL && !this.menuMediaPlayer.stateIsPlaying) {
            this.focusOnReadingPosition();
        }
    };

    _vanillaReaderEvent_navigationTreeReady(ev: CustomEvent<IVanillaReaderAppEvent_NAVIGATION_TREE_READY>) {
        if (this.dialogContents && ev.detail.navigationTreeData) {
            this.navigationTreeData = ev.detail.navigationTreeData;
            this.dialogContents.populate(ev.detail.navigationTreeData);

            // Now that we have the navigation tree, we can select the correct tree item based on the initial reading
            // position.
            let latestReadingPosition: IVanillaReaderReadingPositionData | undefined = this.initialReadingPosition;
            if (latestReadingPosition && latestReadingPosition.navItemData) {
                this.dialogContents.setActiveTocItems(latestReadingPosition.navItemData);
                this.dialogContents.setActivePageItems(latestReadingPosition.navItemData);
            }

            // We can also update the progression label in the menu to show the active toc item.
            // It's just a "refresh" so we just send in the current slider value.
            this.menuBottom.updateSliderProgressionValue(this.menuBottom.sliderProgressionValue);
        }
    };

    _vanillaReaderEvent_readerKeyUp(ev: CustomEvent<IVanillaReaderAppEvent_KEYBOARD_EVENT>) {
        this._handleKeyboardShortcut(ev.detail.srcEventData.key, ev.detail.srcEventData.modifiers.alt, ev.detail.srcEventData.modifiers.shift);
    };

    _vanillaReaderEvent_textSelected(ev: CustomEvent<IVanillaReaderAppEvent_TEXT_SELECTED>) {
        if (ev.detail.srcEventData.isRange) {
            this.activeSelection = {
                selector: '#' + ev.detail.srcEventData.locator?.selectors[0],
                isRange: ev.detail.srcEventData.isRange,
                selectionText: ev.detail.srcEventData.selectionText || '',
            };

            if (this.activeSelection.isRange) {
                this.fabHighlight.show();
            } else {
                this.fabHighlight.hide();
            }

        } else {
            // Wait a bit before clearing the activeSelection variable in order to not create a racing condition
            // when the highlight button is pressed and the selection gets cleared.
            setTimeout(()=>{
                this.activeSelection = undefined;
                this.fabHighlight.hide();
            },200);
        }
    };

    _vanillaReaderEvent_visibleRangeDataChanged(ev: CustomEvent<IVanillaReaderAppEvent_VISIBLE_RANGE_DATA_CHANGED>) {
        let visibleRangeData = ev.detail.visibleRangeData;
        this.latestContentRangeData = visibleRangeData;
        this.dialogHighlights.setSelected(visibleRangeData.highlights);
        this.dialogBookmarks.setSelected(visibleRangeData.bookmarks);
        this.dialogContents.setActiveTocItems(visibleRangeData.navItems);
        this.dialogContents.setActivePageItems(visibleRangeData.navItems);

    };

    _vanillaReaderEvent_timelineReady(ev: CustomEvent<IVanillaReaderAppEvent_TIMELINE_READY>) {
        this.menuBottom?.initializeTimeline(ev.detail.timelineLength);

        // If this is a PDF we don't need to fetch landmarks as PDFs do not contain any meaningful landmark semantics
        // other than those presented in the navigation metadata.
        if (this.initialPublicationData?.format !== 'application/pdf') {
            this._populatePublicationLandmarks();
        }
    };

    _vanillaReaderEvent_publicationLandmarksReady(ev: CustomEvent<IVanillaReaderAppEvent_PUBLICATION_LANDMARKS_READY>) {
        this.publicationHeadingLandmarks = ev.detail.headings;
        this.publicationFootnoteLandmarks = ev.detail.footnotes;
        this.publicationFigureLandmarks = ev.detail.figures;
    };

    _vanillaReaderEvent_documentLandmarksReady(ev: CustomEvent<IVanillaReaderAppEvent_DOCUMENT_LANDMARKS_READY>) {
        this.publicationHeadingLandmarks = ev.detail.headings;
        this.publicationFootnoteLandmarks = ev.detail.footnotes;
        this.publicationFigureLandmarks = ev.detail.figures;
    };

    _vanillaReaderEvent_viewTransitionStarted(_ev: CustomEvent<IVanillaReaderAppEvent_VIEW_TRANSITION_STARTED>) {
        this.isReaderViewTransitioning = true;
    };

    _vanillaReaderEvent_viewTransitionEnded(_ev: CustomEvent<IVanillaReaderAppEvent_VIEW_TRANSITION_ENDED>) {
        this.isReaderViewTransitioning = false;
    };

    /**
     *
     * When the user is panning and zooming, this method is called with the updated transform data. The transform data
     * can also be `undefined` in which case the zoom has been reset.
     *
     * */
    _vanillaReaderEvent_viewTransformChanged(ev: CustomEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_CHANGED>) {

        this.activeTransformData = ev.detail.transformData;

        if(ev.detail.transformData && !this.isReaderViewIsInPanZoomMode) {
            this.isReaderViewIsInPanZoomMode = true;
            if(!this.panZoomTool.isActive) {
                this.panZoomTool.activate();
                this.panZoomTool.enablePanning();
            }

        } else if(!ev.detail.transformData && this.isReaderViewIsInPanZoomMode) {
            this.isReaderViewIsInPanZoomMode = false;
            this.panZoomTool.deactivate();
        }

        this.panZoomTool.activeTransformData = this.activeTransformData;

    };

    _vanillaReaderEvent_mediaPlayerCreated (_ev: CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_CREATED>) {
        this._onMediaPlayerIsReadyToPlay();
    };

    // The `SyncMediaPlayer` instance (TTS, MO and Audiobooks) in the `VanillaReader` has started playing, or has paused.
    _vanillaReaderEvent_mediaPlayerPlaybackStateChanged(ev: CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_PLAYBACK_STATE_CHANGED>){

        if (ev.detail.state.isPaused) {
            this.menuMediaPlayer.stateIsPlaying = false;
        } else {
            this.menuMediaPlayer.stateIsPlaying = true;
        }

        this.fabMediaPlayer.stateIsPlaying = this.menuMediaPlayer.stateIsPlaying;

        if(window.navigator.mediaSession) {
            this.onMediaPlayerPlaybackStateChanged(ev.detail.state);
        }
    };

    // The `VanillaReaderAudiobookMediaPlayer` instance in the `VanillaReader` has progressed to a new timeline position.
    // Note: This event fires every 1000ms.
    _vanillaReaderEvent_mediaPlayerPlaybackPositionChanged(ev: CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_PLAYBACK_POSITION_CHANGED>) {
        if(window.navigator.mediaSession && ev.detail.state) {
            this.onMediaPlayerPlaybackPositionChanged(ev.detail.state);
        }
    };

    // The `VanillaReaderAudiobookMediaPlayer` instance in the `VanillaReader` has progressed to a new segment.
    _vanillaReaderEvent_mediaPlayerSeekCompleted(ev: CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_COMPLETED>) {
        let playbackState = ev.detail.state;
        let segmentTitle = playbackState.navigationItems ? playbackState.navigationItems[0].title : 'Untitled';

        console.log('Media Player has skipped to segment', segmentTitle);

        if (window.navigator.mediaSession) {
            this.onMediaPlayerSeekCompleted(playbackState);
        }

        if (ev.detail.state.navigationItems && ev.detail.state.navigationItems.length >= 0) {
            // Only grab the first item in the navigaionItems array to circumvent a bug in Beta1
            this.dialogContents?.setActiveTocItems([ev.detail.state.navigationItems[0]]);
            this.dialogContents?.setActivePageItems([ev.detail.state.navigationItems[0]]);
        }
    };
}