
import { Cache } from './Cache'
import { Reader } from './Reader'
import swal from 'sweetalert'

/*
*
*  INITIAL SETUP BEFORE READER.
*
* */

// Refresh the app
// async function refresh() {
//     // @ts-ignore
//     window.location.href = window.location.href
// }

// refresh();

(async function () {

    // Cleanup localstorage...
    // localStorage.removeItem('userid')
    // localStorage.removeItem('book_id')
    // localStorage.removeItem('VanillaReaderUserProfileData')
    // localStorage.removeItem('book_name')


    // Delete local Indexed databases...
    // window.indexedDB.deleteDatabase('bookmarks.vanillareader.colibrio.com')
    // window.indexedDB.deleteDatabase('data.vanillareader.colibrio.com')
    // window.indexedDB.deleteDatabase('highlights.vanillareader.colibrio.com')
    // window.indexedDB.deleteDatabase('options.vanillareader.colibrio.com')
    // window.indexedDB.deleteDatabase('publication.vanillareader.colibrio.com')


    // Initialize main reader class.
    const reader = Reader.getInstance()

    // @ts-ignore
    window.swal = swal

    /*
    * Setup environment ( LOCAL | TESTING | STAGING | PRODUCTION ) .
    *
    * If you are working on local then uncomment local and comments others
    *
    * Same for other environment.
    *
    * */
    // reader.ENV = 'LOCAL'; // LOCAL DEVELOPMENT
    // reader.ENV = 'LINUX'; // LINUX LOCAL
     reader.ENV = 'STAGING'; // STAGING SERVER ( staging.imbibliomedia.com )
    // reader.ENV = 'PRODUCTION'; // LIVE SERVER ( imbiliomedia.com )

    /*
     * Check if token param is set, if not then throw error
     *
     * Unless environment is `TESTING` where the book url is set directly.
     *
     * */
    if (!reader.PARAMS['open']) {

        if (!reader.PARAMS['token'] && reader.ENV !== 'TESTING') {

            swal('Failed!', 'Token not set, Launch reader from imbiblioapp.com', 'error')
            Reader.stopTheApp()

        }

    }


    /*
    *
    * Set URLS based on environment.
    *
    * */
    switch (reader.ENV) {

        case 'LOCAL':

            reader.APP_URL = 'http://localhost:1234/'
            reader.API_URL = 'http://localhost:8080'

            reader.DOTNET_SITE_URL = 'http://localhost:5010/'
            reader.DOTNET_SITE_READER_URL = 'http://localhost:5010/ireader/'

            break

        case 'LINUX':

            reader.APP_URL = 'http://localhost:1234/'
            reader.API_URL = 'http://localhost:8080'

            reader.DOTNET_SITE_URL = 'https://imbiblioapp.com/'
            reader.DOTNET_SITE_READER_URL = 'https://imbiblioapp.com/ireader/'

            break

        case 'STAGING':

            reader.APP_URL = 'https://imbiblioreader.zerosoft.in/'
            reader.API_URL = 'https://stagingapi.imbibliomedia.com/'

            reader.DOTNET_SITE_URL = 'https://app11601-stagingnew.azurewebsites.net/'
            reader.DOTNET_SITE_READER_URL = 'https://app11601-stagingnew.azurewebsites.net/ireader/'

            break

        case 'PRODUCTION':

            reader.APP_URL = 'https://imbibliomedia.com/'
            reader.API_URL = 'https://api.imbibliomedia.com'

            reader.DOTNET_SITE_URL = 'https://imbiblioapp.com/'
            reader.DOTNET_SITE_READER_URL = 'https://imbiblioapp.com/ireader/'

            break

        default:

            swal('Failed!', 'Invalid environment detected, please set it again', 'error')
            Reader.stopTheApp()

    }

    /*
     * Set vars in reader class
     *
     * */
    reader.init()

    // Debug.
    // console.log(reader)

    // @ts-ignore
    window.reader = reader

    // Store reader instance in Temporary object map storage
    Cache.set('reader', reader)

    // Debug.
    // console.log(Cache.get('reader'))

})();

